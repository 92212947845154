/* ======================================================================
 component
 ====================================================================== */

/* =================================
 txt
 ================================= */
 //c_txt_bg_1
 .c_txt_bg_1{
  position: relative;
  font-family:$font_2;
  font-size: 1.8rem;
  &.mt_LL_u_pc{
    margin-top: 100px;
  }
  .txt{
    margin-top: 20px;
    text-align: center;
    &.txt_left{
      text-align: left;
    }
    &.mt_L{
      margin-top: 64px;
    }
    &:first-child{
      margin-top: 0;
    }
  }
  &:before{
    content:"";
    width: 472px;
    height: 260px;
    position: absolute;
    top: calc(50% + 40px);
    left: 50%;
    transform:translate(-50% , -50%);
    background: url(../img/common/c_mount.png);
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center ;
    z-index: -1;
  }
  &.bg_L{
    &:before{
      width: 622px;
      height: 342px;
      top: 50%;
    }
  }
  @include sp{
    font-size: 1.6rem;
    &.mt_LL_u_pc{
      margin-top: 0;
    }
    .txt{
      text-align: left;
    }
    &:before{
      width: 100%;
      height: 260px;
      top: 80px;
    }
    &.bg_L{
      &:before{
        width: calc(100% + 80px);
        height: 260px;
        top: 80px;
        left: calc(50% + 20px);
      }
    }
  }
}
//c_txt_bg_2
.c_txt_bg_2{
  position: relative;
  z-index: 2;
  font-family:$font_2;
  font-size: 2.0rem;
  .txt{
    text-align: center;
  }
  &:before{
    content:"";
    width: 226px;
    height: 124px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform:translate(-50% , -50%);
    background: url(../img/common/c_mount_2.png);
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center ;
    z-index: -1;
  }
  @include sp{
    font-size: 1.8rem;
    &:before{
      width: 295px;
      height: 162px;
    }
    .txt{
      text-align: left;
    }
  }
}
//c_txt_1
.c_txt_1{
  font-family:$font_2;
  font-size: 1.8rem;
  + .c_txt_1{
    margin-top: 20px;
  }
  @include sp{
    font-size: 1.6rem;
  }
}
//c_txt_2
.c_txt_2{
  font-family:$font_1;
  font-size: 1.8rem;
  + .c_txt_2{
    margin-top: 20px;
  }
  &.color_black{
    color: $black;
  }
}
//c_txt_3
.c_txt_3{
  font-family:$font_1;
  font-size: 1.6rem;
  + .c_txt_3{
    margin-top: 20px;
  }
}
//c_txt_4
.c_txt_4{
  font-family:$font_1;
  font-size: 1.4rem;
  + .c_txt_4{
    margin-top: 20px;
  }
  &.size_L{
    margin-top: 40px;
  }
}
//c_txt_5
.c_txt_5{
  font-family:$font_2;
  font-size: 1.6rem;
  color: $white;
  + .c_txt_5{
    margin-top: 20px;
  }
  &.size_L{
    margin-top: 40px;
  }
}
//c_txt_bold
.c_txt_bold{
  font-weight: bold;
  font-weight: 700;
}
//c_txt_em
.c_txt_em{
  color: $color_28;
}
//c_txt_notes
.c_txt_notes{
  position: relative;
  font-family:$font_1;
  font-size: 1.2rem;
  padding-left: 16px;
  &:before{
    content:"※";
    position: absolute;
    top: 0;
    left: 0;
    display: block;
  }
  &.link{
    color: $color_35;
    text-decoration: underline;
  }
}
.hover .c_txt_notes{
  &.link{
    &:hover{
      text-decoration: none;
    }
  }
}
//c_txt_ttlBottom
.c_txt_ttlBottom{
  margin-bottom: 20px;
  font-size: 1.8rem;
  color: $black;
  @include sp {
    font-size: 1.6rem;
    line-height: 1.75;
  }
}
//c_txt_ttlBottom_2
.c_txt_ttlBottom_2{
  margin-bottom: 40px;
  font-size: 1.8rem;
  color: $black;
  @include sp{
    font-size: 1.6rem;
    margin-bottom: 30px;
  }
}
//c_txt_name
.c_txt_name{
  position: relative;
  font-weight: bold;
  font-size: 1.6rem;
  &:after{
    content:":";
    margin-left: -8px;
  }
}

/* =================================
box
================================= */
// c_box_1
.c_box_1{
  border: 1px solid $color_5;
  padding: 30px 16px;
}
// c_box_2
.c_box_2{
  background:$color_13;
  padding: 30px 16px;
  &.mb_c_cat{
    padding: 30px 16px 24px;
  }
}
// c_box_3
.c_box_3{
  background:$color_4;
  padding: 30px 16px;
}
// c_box_4
.c_box_4{
  background: $color_26;
  padding: 30px 16px;
  &.size_S{
      padding: 18px 24px;
  }
}
// c_box_5
.c_box_5{
  background: $color_5;
  padding: 40px;
  @include sp {
    padding: 20px 15px;
  }
}
// c_box_6
.c_box_6{
  background:$color_13;
  padding: 30px;
  &.mb_c_cat{
    padding: 320px;
  }
}
// c_box_cat_1
.c_box_cat_1 {
  position: relative;
  display: flex;
  .ttl{
    margin-right: 30px;
  }
  .ttl_2{
    min-width: 140px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .cont{
    flex:1;
  }
  @include sp {
    flex-direction : column;
    .ttl{
      text-align: center;
      margin-right: 0;
    }
  }
}
/* =================================
 title
 ================================= */
 // c_ttl_1
 .c_ttl_1 {
  position: relative;
  font-family:$font_2;
  font-size: 5.6rem;
  line-height: 1.5;
  color: $color_2;
  margin-bottom: 32px;
  z-index: 4;
  .en{
    color: $color_6;
    font-size: 2.0rem;
    position: relative;
    padding-left: 34px;
    display: block;
    &:before {
      content:"";
      position: absolute;
      top: 14px;
      left: 0px;
      width: 24px;
      height: 1px;
      background: $color_6;
    }
  }
  &.light{
    color: $white;
  }

  @include sp{
    font-size: 3.4rem;
    .sub{
      font-size: 1.6rem;
    }
  }
}



// c_ttl_1_child
.c_ttl_1_child {
  position: relative;
  font-family:$font_2;
  font-size: 4.0rem;
  line-height: 1.5;
  color: $white;
  z-index: 4;
  .en{
    color: $color_6;
    font-size: 1.6rem;
    position: relative;
    padding-left: 34px;
    display: block;
    &:before {
      content:"";
      position: absolute;
      top: 12px;
      left: 0px;
      width: 24px;
      height: 1px;
      background: $color_6;
    }
    &.white{
      color: $white;
      &:before {
        background: $white;
      }
    }
  }
  &.light{
    color: $white;
  }

  @include sp{
    font-size: 2.2rem;
    .en{
      font-size: 1.2rem;
      &:before{
        top: 9px;
      }
    }
    .sub{
      font-size: 1.2rem;
    }
  }
}
// c_ttl_1_child_b
.c_ttl_1_child_b {
  position: relative;
  font-family:$font_2;
  font-size: 4.0rem;
  line-height: 1.5;
  color: $white;
  z-index: 4;
  .en{
    color: $white;
    font-size: 1.6rem;
    position: relative;
    padding-left: 34px;
    display: block;
    &:before {
      content:"";
      position: absolute;
      top: 12px;
      left: 0px;
      width: 24px;
      height: 1px;
      background: $white;
    }
  }
  &.light{
    color: $white;
  }

  @include sp{
    font-size: 2.2rem;
    .sub{
      font-size: 1.2rem;
    }
  }
}
// c_ttl_1_child_c
.c_ttl_1_child_c {
  position: relative;
  font-family:$font_2;
  font-size: 4.0rem;
  line-height: 1.5;
  color: $white;
  z-index: 4;
  .en{
    color: $color_6;
    font-size: 1.6rem;
    position: relative;
    padding-left: 34px;
    display: block;
    &:before {
      content:"";
      position: absolute;
      top: 12px;
      left: 0px;
      width: 24px;
      height: 1px;
      background: $color_6;
    }
  }
  &.light{
    color: $white;
  }

  @include sp{
    font-size: 2.2rem;
    .sub{
      font-size: 1.2rem;
    }
  }
}
// c_ttl_2
.c_ttl_2 {
  font-family:$font_2;
  font-size: 4.3rem;
  color: $color_2;
  margin-bottom: 32px;
  text-align: center;
  .en{
    color: $color_6;
    font-size: 2.0rem;
    position: relative;
  }
  @include sp{
    font-size: 3.2rem;
    .sub{
      font-size: 1.6rem;
    }
  }
}
// c_ttl_3
.c_ttl_3 {
  font-family:$font_2;
  font-size: 2.4rem;
  padding-left: 34px;
  position: relative;
  margin-bottom: 30px;
  .sub{
    font-size: 1.6rem;
  }
  &:before {
    content:"";
    position: absolute;
    top: 50%;
    left: 0px;
    transform:translate(0 , -50%);
    width: 24px;
    height: 2px;
    background: $color_6;
  }
  @include sp{
    font-size: 2.0rem;
    .sub{
      font-size: 1.4rem;
      display: block;
    }
  }
}

// c_ttl_4
.c_ttl_4{
  position: relative;
  font-family:$font_2;
  font-size: 3.4rem;
  margin-bottom: 20px;
  position: relative;
  padding-left: 8px;
  &.mb_size_M{
    margin-bottom: 40px;
  }
  &.mb_size_S{
    margin-bottom: 12px;
  }
  &:first-child{
    margin-top: 0;
  }
  &:before {
    content:"";
    position: absolute;
    top: 0px;
    left: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 44px 44px 0 0;
    border-color: $color_sub_2 transparent transparent transparent;
    z-index: -1;
  }
  &.white{
    color: $white;
    &:before {
      opacity:0.5; 
    }
  }
  @include sp{
    margin-top: 50px;
    font-size: 2.8rem;
    line-height: 1.5;
    &:before{
      border-width: 38px 38px 0 0;
    }
  }
}

// c_ttl_5
.c_ttl_5{
  font-family:$font_2;
  font-size: 3.4rem;
  text-align: center;
  position: relative;
  margin-top: 80px;
  margin-bottom: 40px;
  &:first-child{
    margin-top: 0;
  }
  .c_ttl_5_txt{
    position: relative;
    display: inline-block;
    margin: 0 160px;
    padding: 0 16px;
    text-align: center;
    background-color: $white;
  }
  &:before {
    content: "";
    width: 100%;
    height: 2px;
    position: absolute;
    top: 50%;
    left: 0;
    background: $color_2;
  }
  @include sp{
    font-size: 1.9rem;
    line-height: 1.5;
    margin-top: 40px;
    margin-bottom: 20px;
    .c_ttl_5_txt{
      margin: 0 40px;
      padding: 0 16px;
    }
    &:before {
      height: 1px;
    }
  }
}

// c_ttl_6
.c_ttl_6{
  font-family:$font_2;
  font-size: 3.0rem;
  color: $black;
  position: relative;
  padding-top: 30px;
  margin-bottom: 20px;
  display: block;
  &:before , &:after  {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 2px;
    width: 100%;
    background: $color_13;
    z-index: 4;
  }
  &:after  {
    width: 20px;
    background: $color_3;
    z-index: 5;
  }
  @include sp{
    font-size: 2.6rem;
    line-height: 1.5;
    padding-top: 10px;
  }
}
// c_ttl_7
.c_ttl_7 {
  position: relative;
  font-family:$font_2;
  font-size: 3.0rem;
  margin-top: 60px;
  margin-bottom: 20px;
  &:first-child{
    margin-top: 0;
  }
  .en{
    color: $color_6;
    font-size: 2.0rem;
    position: relative;
    padding-left: 34px;
    display: block;
    &:before {
      content:"";
      position: absolute;
      top: 50%;
      left: 0px;
      transform:translate(0 , -50%);
      width: 24px;
      height: 2px;
      background: $color_6;
    }
  }
  @include sp{
    font-size: 2.0rem;
    margin-top: 30px;
    margin-bottom: 10px;
    .sub{
      font-size: 1.5rem;
    }
  }
}
// c_ttl_8
.c_ttl_8{
  font-family:$font_2;
  display: block;
  font-size: 2.8rem;
  color: $black;
  position: relative;
  padding-bottom: 10px;
  margin-bottom: 20px;
  line-height: 1.8;
  &:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    height: 2px;
    width: 100%;
    background: $color_3;
  }
  @include sp{
    font-size: 2.6rem;
    line-height: 1.5;
  }
}
// c_ttl_9
.c_ttl_9{
  font-family:$font_2;
  font-size: 2.6rem;
  position: relative;
  margin-bottom: 10px;
  line-height: 1.6;
  @include sp{
    font-size: 2.4rem;
  }
}
// c_ttl_10
.c_ttl_10{
  font-family:$font_2;
  font-size: 2.4rem;
  position: relative;
  margin-bottom: 20px;
  line-height: 1.5;
  @include sp{
    font-size: 2.0rem;
  }
}
// c_ttl_11
.c_ttl_11{
  font-size: 2.4rem;
  position: relative;
  margin-bottom: 20px;
  padding-left: 12px;
  line-height: 1.6;
  font-weight: bold;
  &:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(0 , -50%);
    height: 88%;
    width: 2px;
    background: $color_6;
  }
  @include sp{
    font-size: 2.0rem;
    &:before {
      height: 94%;
    }
  }
}
// c_ttl_12
.c_ttl_12{
  font-family:$font_2;
  font-size: 2.0rem;
  position: relative;
  line-height: 1.7;
  font-weight: bold;
  color: $color_2;
  @include sp{
    margin-bottom: 20px;
  }
}
// c_ttl_13
.c_ttl_13{
  font-family:$font_2;
  font-size: 2.4rem;
  position: relative;
  margin-bottom: 15px;
  @include sp{
    font-size: 2.6rem;
    margin-bottom: 20px;
  }
}
// c_ttl_14
.c_ttl_14{
  font-family:$font_2;
  font-size: 2.0rem;
  position: relative;
  margin-bottom: 10px;
  @include sp{
    font-size: 2.2rem;
    margin-bottom: 20px;
  }
}
// c_ttl_15
.c_ttl_15{
  font-family:$font_2;
  font-size: 2.4rem;
  color: $color_21;
  position: relative;
  margin-bottom: 20px;
  text-align: center;
  @include sp{
    margin-bottom: 10px;
  }
}
// c_ttl_16
.c_ttl_16 {
  font-family: "Shippori Mincho", "ヒラギノ明朝 ProN W3", "HiraMinProN-W3", "游明朝", Yu mincho, YuMincho, serif;
  font-size: 2.8rem;
  position: relative;
  margin-bottom: 15px;
  line-height: 1.53;
  text-align: center;
  @include sp{
    font-size: 2.6rem;
    margin-bottom: 20px;
  }
}
/* =================================
 pic
 ================================= */
 // c_pic_full
 .c_pic_full{
  width: 100%;
  .img{
    width: 100%;
  }
}
 // c_pic_full_sp
 .c_pic_full_sp{
  max-width: 100%;
  .img{
    width: 100%;
  }
}
// c_pic_L
.c_pic_L{
  position: relative;
  width: 64%;
  .img{
    width: 100%;
  }
  .num{
    font-family: $font_2;
    font-size: 7.2rem;
    color: $color_6;
    position: absolute;
    top: -64px;
    left: -10px;
    z-index: 4;
  }
  @include sp{
    width: 100%;
    .num{
      font-size: 5.6rem;
      top: -56px;
    }
  }
}
// c_pic_M
.c_pic_M{
  position: relative;
  width: 48%;
  .img{
    width: 100%;
  }
  img{
    width: 100%;
  }
  .num{
    font-family: $font_2;
    font-size: 7.2rem;
    color: $color_6;
    position: absolute;
    top: -64px;
    left: -16px;
  }
  @include sp{
    width: 38%;
    .num{
      font-size: 3.2rem;
      top: -32px;
      left: -8px;
    }
  }
}
// c_pic_S
.c_pic_S{
  position: relative;
  width: 34%;
  .img{
    width: 100%;
  }
  img{
    width: 100%;
  }
  .num{
    font-family: $font_2;
    font-size: 7.2rem;
    color: $color_6;
    position: absolute;
    top: -64px;
    left: -16px;
  }
  @include sp{
    width: 36%;
    .num{
      font-size: 3.2rem;
      top: -32px;
      left: -8px;
    }
  }
}

// c_pic_cover
.c_pic_cover{
  position: relative;
  width: 100%;
  height: 300px;
  overflow: hidden;
  .img{
    position: absolute;
    top: 50%;
    left: 50%;
    width: auto;
    min-width: 100%;
    height: auto;
    min-height: 100%;
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    height: 300px;
    width: 1366px;
    object-fit: cover;
    -webkit-backface-visibility: hidden;
  }
  @include sp{
    height: 200px;
    .img{
      height: 200px;
      width: 377px;
      object-fit: cover;
      -webkit-backface-visibility: hidden;
    }
  }
}
// c_pic_cover_2
.c_pic_cover_2{
  position: relative;
  width: 100%;
  overflow: hidden;
  background: $color_2;
}
//c_pic_scroll_sp
.c_pic_scroll_sp{
  max-width: 1100px;
  margin: 0 auto;
  &.ver_2{
    max-width: 900px;
    margin: 0 auto;
  }
  .img{
    width: 100%;
  }
  @include h_change{
    &.ver_2{
      max-width: 100%;
      margin: 0 auto;
      padding: 0 20px;
    }
  }
  @include sp{
    overflow-x: scroll;
    padding-left: 20px;
    .img{
      width: 1100px;
    }
    &.ver_2{
      .img{
        width: 900px;
      }
    }
  }
}
/* =================================
 buttons wrap
 ================================= */
 //c_btn_wrap
 .c_btn_wrap{
  margin-top: 20px;
  position: relative;
  z-index: 4;
  .c_btn_4{
    margin-top: 20px;
  }
  &.size_L{
    margin-top: 40px;
  }
  &.tar{
    justify-content: flex-end;
    text-align: right;
  }
  &.tal{
    justify-content: flex-start;
    text-align: left;
  }
  &.mr{
    .c_btn_3{
      margin-right: 30px;
    }
  }
  &.mr_2{
    .c_btn_3{
      margin-right: 40px;
    }
  }
  &.mt_none{
   margin-top: 0;
  }
  &.mt_none_u_pc{
   margin-top: 0;
  }
  @include pc_tab{
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    .c_btn_1{
      margin-top: 0;
    }
    .c_btn_1:not(:first-child){
      margin-left: 22px;
    }
    &.margin_L{
      margin-top: 40px;
    }
    &.tal{
      align-items: flex-start;
      justify-content: flex-start
    }
    &.tar{
      justify-content: flex-end;
    }
  }

  @include sp{
    &.mt_none_u_pc{
      .c_btn_1:not(:first-child){
        margin-top: 20px;
      }
   }
 }
}
/* =================================
 c_wrap_between
 ================================= */
 .c_wrap_between{
  display: flex;
  justify-content: space-between;
  width: 100%;
}

/* =================================
 buttons
 ================================= */

 // c_btn_1
 .c_btn_1 {
  position: relative;
  background: $color_3;
  border: 2px solid $color_3;
  min-width: 340px;
  display : inline-flex;
  justify-content: center;
  align-items: center;
  padding: 18px;
  font-family: $font_2;
  transition: $all;
  .c_btn_1_txt{
    color: $white;
    font-size: 1.7rem
  }
  &.c_btn_middle{
    min-width: 563px;
    @include sp{
      min-width: 1px;
      width: 100%;
    }
  }
  &.full{
    min-width: 1px;
    width: 100%;
  }
  &.c_btn_m{
    min-width: 462px;
  }
  &.c_btn_s{
    min-width: 223px;
  }
  &.c_input{
    padding: 0;
    input{
      padding: 18px;
      min-width: 340px;
      display : inline-flex;
      justify-content: center;
      align-items: center;
    }
  }
  @include sp{
    min-width: 100%;
    &.c_input{
      input{
        min-width: 1px;
        width: 100%;
      }
    }
    &.c_btn_s , &.c_btn_m{
      min-width: 100%;
    }
  }
  &:before {
    content:"";
    position: absolute;
    right: 20px;
    width: 7px;
    height: 7px;
    border-top: 2px solid $white;
    border-right: 2px solid $white;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    @include sp{
      right: 8px;
    }
  }
  &.pdf{
    &:before {
     content: "";
     width: 12px;
     height: 16px;
     position: absolute;
     top: 50%;
     right: 10px;
     -webkit-transform: translate(0, -50%);
     transform: translate(0, -50%);
     background: url(../img/common/ico_pdf_white.svg);
     background-repeat: no-repeat;
     background-size: 100%;
     background-position: center;
     border: none;
   }
 }
 &.color_sub_1 {
  background: $color_sub_1;
  border: 2px solid $color_sub_1;
}

&.color_sub_2 {
  background: $color_sub_2;
  border: 2px solid $color_sub_2;
  .c_btn_1_txt{
    color: $color_1;
  }
  &:before {
    border-top: 2px solid $color_1;
    border-right: 2px solid $color_1;
  }
}
&.color_sub_3{
  background: $color_20;
  border: 2px solid $color_20;
}

&.white {
  background: $white;
  border: 2px solid $white;
  .c_btn_1_txt{
    color: $color_1;
  }
  &:before {
    border-top: 2px solid $color_1;
    border-right: 2px solid $color_1;
  }
}
&.color_tp {
  background: $color_tp;
  border: 2px solid $white;
  .c_btn_1_txt{
    color: $white;
  }
  &:before {
    border-top: 2px solid $color_tp;
    border-right: 2px solid $color_tp;
  }
}
}
// c_btn_1 hover
.hover{
  .c_btn_1{
    &:hover {
      background: $white;
      border: 2px solid $color_3;
      .c_btn_1_txt {
        color: $color_25;
      }
      &:before {
        border-top: 2px solid $color_3;
        border-right: 2px solid $color_3;
      }
      &.pdf{
        &:before {
          border: none;
          background: url(../img/common/ico_pdf.svg);
          background-repeat: no-repeat;
          background-size: 100%;
          background-position: center;
          border: none;
        }
      }
    }

    &.color_sub_1:hover {
      background: $white;
      border: 2px solid $color_sub_1;
      .c_btn_1_txt {
        color: $color_sub_1;
      }

      &:before {
        border-top: 2px solid $color_sub_1;
        border-right: 2px solid $color_sub_1;
      }
    }

    &.color_sub_2:hover {
      background: $white;
      border: 2px solid $color_sub_2;

      .c_btn_1_txt {
        color: $color_1;
      }

      &:before {
        border-top: 2px solid $color_1;
        border-right: 2px solid $color_1;
      }
    }

    &.white:hover {
      background: $color_tp;
      border: 2px solid $white;

      .c_btn_1_txt {
        color: $white;
      }

      &:before {
        border-top: 2px solid $white;
        border-right: 2px solid $white;
      }
    }
  }
}
// c_btn_2
.c_btn_2 {
  position: relative;
  display: inline-block;
  padding-bottom: 6px;
  font-size: 1.4rem;
  transition: $all;
  &:after {
    content:"";
    width: 100%;
    height: 1px;
    position: absolute;
    bottom: 0;
    right: 0;
    background: $color_1;
    transition: $all;
  }
}
// c_btn_2 hover
.hover{
  .c_btn_2 {
    &:hover {
      opacity: 0.5;
      &:after {
        width: 0;
      }
    }
  }
}

// c_btn_3
.c_btn_3 {
  position: relative;
  display: inline-block;
  padding: 14px 40px 14px 8px;
  font-size: 1.7rem;
  transition: $all;
  &:before{
    content: "";
    position: absolute;
    top:50%;
    right: 20px;
    width: 7px;
    height: 7px;
    border-top: 2px solid $color_3;
    border-right: 2px solid $color_3;
    transform: translate(0 , -50%) rotate(45deg);
  }
  &:after {
    content:"";
    width: 100%;
    height: 4px;
    position: absolute;
    bottom: 0;
    right: 0;
    background: $color_3;
    transition: $all;
  }
  .c_btn_3_txt {
    color: $color_3;
    font-family: $font_2;
  }
  &.pdf{
    &:before {
     content: "";
     width: 12px;
     height: 16px;
     position: absolute;
     top: 50%;
     right: 10px;
     -webkit-transform: translate(0, -50%);
     transform: translate(0, -50%);
     background: url(../img/common/ico_pdf.svg);
     background-repeat: no-repeat;
     background-size: 100%;
     background-position: center;
     border: none;
   }
 }
  @include sp{
    font-size: 1.5rem;
  }
}
// c_btn_3 hover
.hover{
  .c_btn_3{
   &:hover {
    opacity: 0.5;
    &:after {
      width: 0;
    }
  }
}
}

// c_btn_4
.c_btn_4 {
  position: relative;
  background: $color_20;
  border: 2px solid $color_20;
  min-width: 340px;
  display : inline-flex;
  flex-direction : column;
  justify-content: center;
  align-items: center;
  padding: 18px;
  font-family: $font_2;
  transition: $all;
  .c_btn_4_txt{
    color: $white;
    font-size: 1.7rem
  }
  .c_btn_4_top{
    display: flex;
    align-items: center;
    .ico{
      width: 16px;
      height: 16px;
      margin-right: 4px;
      .img{
        width: 100%;
      }
    }
    .icoL{
      width: 24px;
      height: 24px;
    }
  }
  .c_btn_4_bottom{
    color: $white;
    font-size: 1.2rem;
    margin-top: 4px;
  }
  @include h_change{
    .c_btn_4_top{
      .ico_phon{
       margin-top: -16px;
     }
   }
 }
  @include sp{
    min-width: 100%;
    &.c_btn_s{
      min-width: 100%;
    }
  }
  &.color_sub_1 {
    background: $color_sub_1;
    border: 2px solid $color_sub_1;
  }
  &.color_tp {
    background: $color_tp;
    border: 2px solid $white;
    .c_btn_1_txt{
      color: $white;
    }
    &:before {
      border-top: 2px solid $color_tp;
      border-right: 2px solid $color_tp;
    }
  }
}
// c_btn_4 hover
.hover{
  .c_btn_4:hover{
    opacity: 0.5;
  }
}



// c_btn_5
.c_btn_5{
  font-family: $font_2;
  font-weight: 700;
  position: relative;
  display: inline-block;
  padding-right: 18px;
  font-size: 1.7rem;
  transition: $all;
  color: $color_3;
  &:after {
    content: "";
    position: absolute;
    right: 0;
    top:10px;
    width: 8px;
    height: 8px;
    border-top: 2px solid $color_3;
    border-right: 2px solid $color_3;
    transform: rotate(45deg);
  }
  @include sp{
    font-size: 1.3rem
  }
}
a.c_btn_5{
  color: $color_3;
}
// c_btn_5 hover
.hover{
  .c_btn_5:hover{
    opacity: 0.5;
  }
}




// c_btn_6
.c_btn_6{
  font-family: $font_2;
  position: relative;
  display: inline-block;
  padding-right: 12px;
  font-size: 1.3rem;
  transition: $all;
  &:after {
    content: "";
    position: absolute;
    right: 1px;
    top:8px;
    width: 6px;
    height: 6px;
    border-top: 1px solid $color_1;
    border-right: 1px solid $color_1;
    transform: rotate(45deg);
  }
  &.blue{
    color: $color_3;
    &:after {
      border-top: 1px solid $color_3;
      border-right: 1px solid $color_3;
    }
  }
}
a.c_btn_6{
  color: $color_3;
}
// c_btn_6 hover
.hover{
  .c_btn_6:hover{
    opacity: 0.5;
  }
}


// c_btn_7
.c_btn_7{
  position: relative;
  display: inline-block;
  font-size: 1.6rem;
  transition: $all;
  color: $color_35;
  &:after {
    content: "";
    width: 12px;
    height: 14px;
    margin-left: 6px;
    display: inline-block;
    background: url(../img/common/ico_tab.svg);
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center;

  }
}
a.c_btn_7{
  color: $color_35;
}
// c_btn_7 hover
.hover{
  .c_btn_7:hover{
    opacity: 0.5;
  }
}



// c_btn_back
.c_btn_back {
  position: relative;
  background: $white;
  border: 1px solid $color_25;
  min-width: 340px;
  display : inline-flex;
  justify-content: center;
  align-items: center;
  padding: 18px;
  font-family: $font_2;
  transition: $all;
  .c_btn_back_txt{
    color: $color_25;
    font-size: 1.7rem
  }
  &.full{
    min-width: 1px;
    width: 100%;
  }
  &.c_btn_s{
    min-width: 223px;
  }
  &.c_input{
    padding: 0;
    input{
      padding: 18px;
      min-width: 340px;
      display : inline-flex;
      justify-content: center;
      align-items: center;
    }
  }
  @include sp{
    min-width: 100%;
    &.c_input{
      input{
        min-width: 1px;
        width: 100%;
      }
    }
    &.c_btn_s{
      min-width: 100%;
    }
  }
  &:before {
    content:"";
    position: absolute;
    top:50%;
    left: 10px;
    width: 8px;
    height: 8px;
    border-top: 2px solid $color_25;
    border-right: 2px solid $color_25;
    -webkit-transform: translate(0 , -50%) rotate(-135deg);
    transform: translate(0 , -50%) rotate(-135deg);
  }
}
// c_btn_back hover
.hover{
  .c_btn_back:hover{
    opacity: 0.5;
  }
}

// c_btn_toggle
.c_btn_toggle {
  position: relative;
  background: $white;
  min-width: 340px;
  display : inline-flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
  font-size: 2.0rem;
  font-weight: bold;
  font-weight: 700;
  transition: $all;
  cursor: pointer;
  color: $color_2;
  .c_btn_toggle_txt{
    color: $color_25;
    font-size: 1.7rem
  }
  &:before {
    content:"";
    position: absolute;
    top: calc(50% - 10px);
    right: 20px;
    width: 14px;
    height: 14px;
    border-top: 2px solid $color_25;
    border-right: 2px solid $color_25;
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg);
    transition: $all;
  }
  &.active{
    &:before {
      -webkit-transform: rotate(-45deg);
      transform: rotate(-45deg);
    }
  }
  @include sp{
    min-width: 100%;
  }
}
// c_btn_toggle hover
.hover{
  .c_btn_toggle:hover{
    opacity: 0.5;
  }
}



// c_btn_8
.c_btn_8{
  font-weight: bold;
  font-weight: 700;
  position: relative;
  display : inline-flex;
  align-items: center;
  font-size: 1.6rem;
  transition: $all;
  color: $color_30;
  .ico{
    width: 10px;
    height: 10px;
    position: relative;
    margin-right: 4px;
    img{
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}
.c_btn_8 a[href^="tel"]{
  color: $color_30;
  display: block;
}


// c_btn_9
.c_btn_9{
  position: relative;
  display: block;
  padding: 6px 0;
  padding-left: 20px;
  font-size: 1.4rem;
  transition: $all;
  font-weight: bold;
  color: $color_2;
  &:after {
    content: "";
    position: absolute;
    left: 0;
    top:50%;
    width: 8px;
    height: 8px;
    border-top: 2px solid $color_3;
    border-right: 2px solid $color_3;
    transform: translate(0 , -50%) rotate(45deg);
  }
  .num{
    position: relative;
    &:before{
      content:"(";
    }
    &:after{
      content:")";
    }
  }
}
a.c_btn_9{
  color: $color_2;
}
// c_btn_9 hover
.hover{
  a.c_btn_9:hover{
    opacity: 0.5;
  }
}
// c_btn_10
.c_btn_10{
  font-family: $font_2;
  position: relative;
  display: inline-block;
  padding-right: 20px;
  font-size: 2.0rem;
  transition: $all;
  font-weight: bold;
  &:after {
    content: "";
    position: absolute;
    right: 0;
    top:50%;
    width: 10px;
    height: 10px;
    border-top: 2px solid $color_6;
    border-right: 2px solid $color_6;
    transform: translate(0 , -50%) rotate(45deg);
  }
}

// c_btn_10 hover
.hover{
  .c_btn_10:hover{
    opacity: 0.5;
  }
}
// c_btn_11
.c_btn_11{
  position: relative;
  display: inline-block;
  padding-right: 10px;
  font-size: 1.4rem;
  transition: $all;
  font-weight: bold;
  &:after {
    content: "";
    position: absolute;
    right: 0;
    top:7px;
    width: 8px;
    height: 8px;
    border-top: 2px solid $color_6;
    border-right: 2px solid $color_6;
    transform: rotate(45deg);
  }
}

// c_btn_11 hover
.hover{
  .c_btn_11:hover{
    opacity: 0.5;
  }
}





// c_btn_12
.c_btn_12{
  font-size: 1.8rem;
  position: relative;
  font-weight: bold;
  padding-bottom: 10px;
  padding-left: 24px;
  color: $color_1;
  border-bottom: 2px solid $color_5;
  display: block;
  transition: $all;
  &:before{
    content: "";
    position: absolute;
    left: 0;
    top: 9px;
    width: 12px;
    height: 12px;
    border-top: 2px solid $color_1;
    border-right: 2px solid $color_1;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}
// c_btn_12 hover
.hover{
  .c_btn_12:hover{
    opacity: 0.5;
  }
}


// c_btn_13
.c_btn_13{
  font-size: 1.6rem;
  position: relative;
  padding-left: 18px;
  color: $color_1;
  display: block;
  transition: $all;
  &:before{
    content: "";
    position: absolute;
    left: 0;
    top: 10px;
    width: 8px;
    height: 8px;
    border-top: 2px solid $color_7;
    border-right: 2px solid $color_7;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  &.ico_tab{
    &:before{
      content: "";
      background: url(../img/common/ico_tab_light.svg);
      background-repeat: no-repeat;
      background-size: 100%;
      background-position: center;
      position: absolute;
      left: 0;
      top: 7px;
      width: 14px;
      height: 14px;
      border-top: none;
      border-right: none;
      -webkit-transform: none;
      transform: none;
    }
  }
}
// c_btn_13 hover
.hover{
  .c_btn_13:hover{
    opacity: 0.5;
  }
}


// c_btn_14
.c_btn_14{
  font-size: 1.2rem;
  position: relative;
  padding-left: 14px;
  color: $color_1;
  display: block;
  transition: $all;
  &:before{
    content: "";
    position: absolute;
    left: 0;
    top: 8px;
    width: 8px;
    height: 2px;
    background: $color_7;
  }
}
// c_btn_14 hover
.hover{
  .c_btn_14:hover{
    opacity: 0.5;
  }
}


// c_btn_15
.c_btn_15{
  font-size: 1.8rem;
  position: relative;
  background: $color_17;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: $font_2;
  padding: 12px;
  transition: $all;
  min-width: 350px;
  border-radius: 40px;
  &:before{
    content: "";
    position: absolute;
    right: 20px;
    width: 7px;
    height: 7px;
    border-top: 2px solid $white;
    border-right: 2px solid $white;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  .c_btn_15_txt{
    color: $white;
    margin-top: -2px;
  }
  &.light{
    background: $color_5;
    &:before{
      border-top: 2px solid $color_21;
      border-right: 2px solid $color_21
    }
    .c_btn_15_txt{
      color: $color_21;
    }
  }
  &.ico_none{
    &:before{
      content:none;
    }
  }
  &.full{
    min-width: 1px;
    width: 100%;
  }
}
// c_btn_15 hover
.hover{
  a.c_btn_15:hover{
    opacity: 0.5;
  }
}



/* =================================
 icon
 ================================= */
 .c_ico_1 {
  background: $color_sub_1;
  color: $white;
  font-size: 1.6rem;
  display: inline-block;
  padding: 6px 10px;
  font-weight: bold;
}

.c_ico_2 {
  width: 76px;
  height: 76px;
  background: $white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-weight: 700;
}

.c_ico_3 {
}

/* =================================
 table
 ================================= */
 // c_table_1
 .c_table_1 {
  width: 100%;
  font-size: 1.4rem;
  .th,.td{
    padding: 20px;
  }
  .th{
    background: $color_21;
    color: $white;
    border-bottom: 1px solid $white;
    width: 253px;
    &.size_S{
      width: 96px;
    }
  }
  .td{
    border-bottom: 1px solid $color_22;
  }
  .tr{
    &:last-child{
      .th,.td{
        border-bottom: none;
      }
    }
  }
  &.size_S{
    .th,.td{
      padding: 8px 16px;
    }
  }
  @include sp{
    .th,.td{
      padding: 8px 15px;
    }
    &.sp_block{
      .th,.td{
        width: 100%;
        display: block;
      }
    }
  }
}
// c_table_1_B
 .c_table_1_B {
  width: 100%;
  font-size: 1.4rem;
  .th,.td{
    padding: 20px;
  }
  .th{
    background: $color_21;
    color: $white;
    border-bottom: 1px solid $white;
    text-align: center;
    border-left: 1px solid $color_22;
    &:last-child{
      border-right: 1px solid $color_22;
    }
  }
  .td{
    border-bottom: 1px solid $color_22;
    border-left: 1px solid $color_22;
    &:last-child{
      border-right: 1px solid $color_22;
    }
    &.date{
      white-space: nowrap;
      vertical-align: middle;
    }
    &.money{
      white-space: nowrap;
      vertical-align: middle;
    }
  }
  @include sp{
    .th,.td{
      padding: 8px 15px;
      font-size: 1.3rem;
    }
  }
}

// c_table_1_C
 .c_table_1_C {
  width: 100%;
  font-size: 1.4rem;
  .th,.td{
    padding: 20px;
  }
  .th{
    background: $color_5;
    border-bottom: 1px solid $white;
    text-align: center;
    border-left: 1px solid $color_22;
    vertical-align: middle;
    &:last-child{
      border-right: 1px solid $color_22;
    }
    &.th_B{
      background: $color_21;
      color: $white;
    }
    &.date{
      width: 30%;
    }
  }
  .td{
    border-bottom: 1px solid $color_22;
    border-left: 1px solid $color_22;
    &:last-child{
      border-right: 1px solid $color_22;
    }
  }
  @include sp{
    .th,.td{
      padding: 8px 15px;
      font-size: 1.3rem;
    }
  }
}
// c_table_2
.c_table_2 {
  width: 100%;
  .th,.td{
    padding: 12px 24px;
    padding-bottom: 10px;
  }
  .th{
    background: $color_24;
    border-bottom: 1px solid $white;
    font-size: 1.6rem;
  }
  .td{
    border-bottom: 1px solid $color_22;
    font-size: 1.4rem;
  }
  .tr{
    &:last-child{
      .th{
        border-bottom: none;
      }
    }
  }
  @include sp{
    .th,.td{
      width: 100%;
      display: block;
      padding: 8px 12px;
      padding-bottom: 6px;
      border-bottom: none;
    }
    .th{
      font-size: 1.4rem;
    }
  }
}

// c_table_3
.c_table_3 {
  width: 100%;
  font-size: 1.6rem;
  .th,.td{
    padding: 12px 24px;
    padding-bottom: 10px;
  }
  .th{
    background: $color_25;
    color: $white;
    border-bottom: 1px solid $white;
  }
  .td{
    border-bottom: 1px solid $color_22;
  }
  .tr{
    &:last-child{
      .th,.td{
        border-bottom: none;
      }
    }
    &:nth-child(even){
      .th{
        background: $color_26;
      }
    }
  }
  @include sp{
    .th{
      width: 120px;
      text-align: center;
    }
    .th,.td{
      padding: 8px 12px;
      padding-bottom: 6px;
    }
  }
}

// c_table_4
.c_table_4 {
  width: 100%;
  font-size: 1.6rem;
  background: $color_13;
  .th,.td{
    padding-bottom: 10px;
    width: 50%;
  }
  .th{
    padding: 20px 40px;
    background: $color_25;
    border-right: 2px solid $white;
    border-bottom: 2px solid $white;
    color: $white;
    text-align: center;
    &.bg_light{
      background: $color_26;
    }
    &.bg_blue{
      background: $color_3;
      border-bottom:none;
      border-right:none;
    }
    &.bg_yellow{
      background: $color_24;
      border-bottom:none;
      border-right:none;
    }
    &:nth-child(even){
      border-right: none;
    }
  }
  .td{
    padding: 40px;
    border-bottom: 2px solid $white;
    border-right: 2px solid $white;
    &:nth-child(even){
      border-right: none;
    }
  }
  .tr{
    &:last-child{
      .th,.td{
        border-bottom: none;
      }
    }
  }
  @include sp{
    .th{

      text-align: center;
    }
    .th,.td{
      padding: 8px 12px;
      padding-bottom: 6px;
    }
  }
}



// c_table_5
.c_table_5 {
  width: 100%;
  .th,.td{
    width: 50%;
    vertical-align: middle;
  }
  .th{
    padding: 15px;
    background: $color_25;
    border-right: 2px solid $color_4;
    border-bottom: 2px solid $color_4;
    color: $white;
    text-align: center;
    font-size: 2.2rem;
    &.bg_light{
      background: $color_26;
    }
    &:nth-child(even){
      border-right: none;
    }
  }
  .td{
    padding: 12px;
    border-bottom: 2px solid $color_4;
    border-right: 2px solid $color_4;
    text-align: center;
    background: $white;
    font-size: 1.6rem;
    &:nth-child(even){
      border-right: none;
    }
  }
  .tr{
    &:nth-child(odd){
      .td{
        background: $color_5;
      }
    }
    &:last-child{
      .th,.td{
        border-bottom: none;
      }
    }
  }
  @include sp{
    .th{
      font-size: 1.6rem;
      text-align: center;
    }
    .th,.td{
      padding: 8px 12px;
      padding-bottom: 6px;
    }
    .td{
      font-size: 1.4rem;
    }
  }
}

/* =================================
 link
 ================================= */
 //c_link_1
 .c_link_1{
  text-decoration: underline;
  color: $color_23;
  font-size: 1.6rem;
  &.size_S{
    font-size: 1.4rem;
  }
}
a.c_link_1{
  color: $color_23;
}
.hover{
  .c_link_1:hover{
    text-decoration: none;
  }
}
//c_link_2
.c_link_2{
  text-decoration: underline;
  color: $color_3;
  font-size: 1.6rem;
  font-weight: bold;
  position: relative;
  display: inline-block;
  &.pdf{
    &:after {
      content: "";
      width: 14px;
      height: 18px;
      background: url(../img/common/ico_pdf.svg);
      background-repeat: no-repeat;
      background-size: 100%;
      background-position: center;
      z-index: 4;
      display: inline-block;
      margin-left: 8px;
    }
  }
}
a.c_link_2{
  color: $color_3;
}
.hover{
  .c_link_2:hover{
    text-decoration: none;
  }
}
 //c_link_3
 .c_link_3{
  text-decoration: underline;
  color: $color_35;
  font-size: 1.6rem;
  &.size_S{
    font-size: 1.4rem;
  }
}
a.c_link_3{
  color: $color_35;
}
.hover{
  .c_link_3:hover{
    text-decoration: none;
  }
}
//c_link_anchor
.c_link_anchor{
  position: relative;
  font-size: 1.6rem;
  font-weight: bold;
  padding-right: 10px;
  transition: $all;
  &:after{
    content: "";
    position: absolute;
    top: 4px;
    right: 0;
    width: 8px;
    height: 8px;
    border-top: 2px solid $color_3;
    border-right: 2px solid $color_3;
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg);
    -webkit-transition: 0.3s all;
  }
}
.hover{
  .c_link_anchor:hover{
    opacity: 0.5
  }
}
//c_link_outside
.c_link_outside{
  position: relative;
  display: inline-block;
  font-size: 1.6rem;
  transition: $all;
  color: $color_35;
  padding-right: 14px;
  &:after{
    content: "";
    width: 14px;
    height: 14px;
    background: url(../img/common/ico_outside.svg);
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center;
    z-index: 4;
    display: inline-block;
  }
}
a.c_link_outside{
  color: $color_35; 
}
/* =================================
 list
 ================================= */
 // c_list_1
 .c_list_1 {
  position: relative;
  .c_list_1_item{
    font-size: 1.6rem;
    position: relative;
    margin-bottom: 8px;
    padding-left: 24px;
    &:before{
      content:"";
      width: 15px;
      height: 15px;
      border-radius: 50%;
      background: $color_6;
      display: block;
      position: absolute;
      top: 6px;
      left: 0;
    }
    &:last-child{
      margin-bottom: 0;
    }
  }
  &.mb0{
    .c_list_1_item{
      margin-bottom: 0;
    }
  }
}
// c_list_1_size_s
.c_list_1_size_s {
  position: relative;
  .c_list_1_item{
    font-size: 1.4rem;
    position: relative;
    margin-bottom: 4px;
    padding-left: 14px;
    &:before{
      content:"";
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: $color_6;
      display: block;
      position: absolute;
      top: 4px;
      left: 0;
    }
    &:last-child{
      margin-bottom: 0;
    }
  }
}
 // c_list_1_em
 .c_list_1_em {
  font-family: $font_2;
  position: relative;
  .c_list_1_em_item{
    font-size: 2.0rem;
    position: relative;
    margin-bottom: 10px;
    padding-left: 24px;
    &.size_S{
      font-size: 1.8rem;
    }
    &:before{
      content:"";
      width: 18px;
      height: 18px;
      border-radius: 50%;
      background: $color_6;
      display: block;
      position: absolute;
      top: 7px;
      left: 0;
    }
    &:last-child{
      margin-bottom: 0;
    }
  }
  @include sp {
      .c_list_1_em_item{
        font-size: 1.8rem;
      }
  }
}
// c_list_2
.c_list_2 {
  position: relative;
  .c_list_2_item{
    font-size: 1.6rem;
    position: relative;
    margin-bottom: 8px;
    padding-left: 18px;
    counter-increment: li;
    &:before{
      content:counter(li) '.';
      color: $color_6;
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      font-weight: bold;
    }
    &:nth-child(n + 10) {
      padding-left: 28px;
    }
    &:last-child{
      margin-bottom: 0;
    }
  }
  .c_list_2_inner{
    padding-left: 1rem;
    counter-reset: cnt;
    .c_list_2_inner_item{
      position: relative;
      padding-left: 18px;
      margin-top: 8px;
      &:before{
        counter-increment: cnt;
        content: counters(cnt, ".") ". ";
        color: $color_6;
        display: block;
        position: absolute; 
        top: 0;
        left: 0;
        font-weight: bold;
      }
    }
  }
}

// c_list_3
.c_list_3 {
  display: flex;
  justify-content: space-between;
  flex-wrap : wrap;
  .c_list_3_item{
    width: calc(33.33333% - 12px);
    margin-top: 16px;
  }
  &:after{
    content:"";
    display: block;
    width: calc(33.33333% - 12px);
  }
  @include sp{
    .c_list_3_item{
      width: 100%;
      margin-top: 30px;
      &:first-child{
        margin-top: 0;
      }
    }
  }
}
// c_list_4
.c_list_4 {
  display: flex;
  justify-content: space-between;
  flex-wrap : wrap;
  .c_list_4_item{
    width: calc(50% - 8px);
    margin-top: 16px;
    &:nth-child(-n+2){
      margin-top: 0;
    }
  }
  @include sp{
    .c_list_4_item{
      width: 100%;
      margin-top: 16px;
      &:nth-child(-n+2){
        margin-top: 16px;
      }
      &:first-child{
        margin-top: 0;
      }
    }
  }
}


// c_list_5
.c_list_5 {
  position: relative;
  counter-reset: number 0;
  .c_list_5_item{
    font-size: 1.6rem;
    position: relative;
    margin-bottom: 8px;
    padding-left: 20px;
    &:before{
      counter-increment: number 1;
      content: counter(number,lower-alpha) '.';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      font-weight: bold;
    }
    &:last-child{
      margin-bottom: 0;
    }
  }
}


 // c_list_6
 .c_list_6 {
  position: relative;
  li{
    font-size: 1.6rem;
    position: relative;
    margin-bottom: 8px;
    padding-left: 24px;
    &:before{
      content: "";
      display: block;
      position: absolute;
      top: 4px;
      left: 6px;
      width: 7px;
      height: 12px;
      -webkit-transform: rotate(40deg);
      transform: rotate(40deg);
      border-bottom: 2px solid $color_17;
      border-right: 2px solid $color_17;
    }
    &:last-child{
      margin-bottom: 0;
    }
  }
}

//c_list_7
.c_list_7 {
  position: relative;
  .c_list_7_item{
    font-family: $font_2;
    color: $color_17;
    font-size: 2.0rem;
    position: relative;
    margin-bottom: 4px;
    padding-left: 24px;
    counter-increment: li;
    &:before{
      content:counter(li);
      color: $color_17;
      display: block;
      position: absolute;
      top: 0;
      left: 7px;
      font-weight: bold;
    }
    &:after{
      content:"";
      display: inline-block;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      border: 1px solid $color_17;
      background: $color_tp;
      position: absolute;
      left: 0;
      top: 6px;
    }
    &:nth-child(n + 10) {
      padding-left: 28px;
      &:before{
        letter-spacing: -1px;
        font-size: 1.8rem;
        left: 2px;
        top: 1px;
      }
    }
    &:last-child{
      margin-bottom: 0;
    }
  }
  @include sp{
    .c_list_7_item{
      font-size: 1.8rem;
    }
  }
}
//c_list_8
.c_list_8 {
  position: relative;
  .c_list_8_item{
    display: flex;
    position: relative;
    padding-left: 77px;
    counter-increment: li;
    margin-bottom: 40px;
    &:before{
      content:'0' counter(li);
      color: $white;
      display: block;
      position: absolute;
      font-size: 2.8rem;
      top: 3px;
      left: 11px;
      font-weight: bold;
      font-family: $font_2;
      z-index: 4;
    }
    &:after{
      content:"";
      display: inline-block;
      width: 56px;
      height: 56px;
      border-radius: 50%;
      background: $color_21;
      position: absolute;
      left: 0;
      top: 0;
    }
    &:nth-child(n + 10) {
      &:before{
        content: counter(li);
      }
    }
    &:last-child{
      margin-bottom: 0;
    }
    .c_list_8_ttl{
      font-weight: bold;
      font-family: $font_2;
      font-size: 2.8rem;
      width: 38%;
    }
    .c_list_8_txt{
      font-size: 1.6rem;
      flex:1;
    }
  }
  @include sp{
    .c_list_8_item{
      flex-direction : column;
      padding-left: 0;
      &:before{
        font-size: 2.4rem;
        top: 2px;
        left: 9px;
      }
      &:after{
        width: 46px;
        height: 46px;
      }
      .c_list_8_ttl{
        font-size: 2.4rem;
        width: 100%;
        padding-left: 56px;
      }
      .c_list_8_txt{
        margin-top: 16px;
      }
    }
  }
}

 // c_list_9
 .c_list_9 {
  position: relative;
  .c_list_9_item{
    font-size: 1.4rem;
    position: relative;
    padding-left: 16px;
    &:before{
      content:"・";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}
 // c_list_10
 .c_list_10 {
  position: relative;
  .c_list_10_item{
    font-size: 1.6rem;
    position: relative;
    padding-left: 20px;
    &:before{
      content:"●";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      color: $color_6;
    }
    &:last-child{
      margin-bottom: 0;
    }
  }
}
// c_list_bnrArea
.c_list_bnrArea{
  display: flex;
  justify-content: space-between;
  flex-wrap : wrap;
  width: 100%;
  max-width: calc($content_width_2 + 20px);
  margin: 0 auto;
  @include tab_content {
    width: 60%;
  }
  @include sp {
    width: 100%;
  }
  li {
    width: calc(25% - 20px);
    margin: 0 10px;
    img {
      width: 100%;
    }
    @include tab_content {
      width: calc(50% - 20px);
      margin: 10px;
    }
    @include sp {
      width: calc(50% - 8px);
      margin: 4px;
    }
  }
}
// c_list_side_bnr_item
.c_list_side_bnr_item{
  transition: $all;
  display: block;
  margin-top: 10px;
  img{
    width: 100%;
  }
}
.hover{
  a.c_list_side_bnr_item:hover{
    opacity: 0.5
  }
}

// c_list_flex
.c_list_flex{
  display: flex;
  flex-wrap : wrap;
  .c_list_flex_item{
    margin-right: 10px;
    &.mb_L{
      margin-bottom: 20px;
    }
  }
  .c_cat_3{
    margin-right: 0;
  }
  &.size_L{
    .c_list_flex_item{
      margin-right: 30px;
    }
  }
  @include sp {
    flex-direction : column;
    .c_list_flex_item{
      margin-right: 0;
      &:last-child{
       .c_cat_3{
        margin-bottom: 0;
       }
      }
    }
    .c_cat_3{
      display: flex;
    }
  }
}

// c_list_flex_2
.c_list_flex_2{
  display: flex;
  flex-wrap : wrap;
  .c_list_flex_2_item{
    margin-right: 10px;
  }
  &.size_L{
    .c_list_flex_2_item{
      margin-right: 30px;
    }
  }
  @include sp {
    justify-content: space-between;
    .c_list_flex_2_item{
      width: calc(50% - 20px)
    }
  }
}

// c_list_flex_3
.c_list_flex_3{
  display: flex;
  flex-wrap : wrap;
  .c_list_flex_3_item{
    margin-right: 10px;
  }
  &.size_L{
    .c_list_flex_3_item{
      margin-right: 30px;
    }
  }
}


// c_list_flex_4
.c_list_flex_4 {
  display: flex;
  justify-content: space-between;
  flex-wrap : wrap;
  .c_list_flex_4_item{
    width: calc(50% - 8px);
    margin-top: 16px;
    &:nth-child(-n+2){
      margin-top: 0;
    }
  }
}


// c_list_flex_5
.c_list_flex_5 {
  display: flex;
  justify-content: space-between;
  flex-wrap : wrap;
  .c_list_flex_4_item{
    width: 50%;
  }
}


// c_list_common
.c_list_common{
  .c_list_common_item{
    margin-top: 40px;
    &:first-child{
      margin-top: 0;
    }
    @include sp {
      margin-top: 50px;
    }
  }
}
// c_list_rank
.c_list_rank_num{
  position: absolute;
  top: -4px;
  left: -4px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background: $color_32;
  font-weight: bold;
  font-size: 1.4rem;
  z-index: 4;
  &:after{
    content:counter(number);
  }
}
// c_list_rank
.c_list_rank_item{
  counter-increment: number;
  border-bottom: 2px solid $color_5;
  padding: 14px 0 12px;
  &:last-child{
    border-bottom: none;
  }
  &:first-child{
    .c_list_rank_num{
      background: $color_6;
      color: $white;
    }
  }
  &:nth-child(2){
    .c_list_rank_num{
      background: $color_33;
      color: $white;
    }
  }
  &:nth-child(3){
    .c_list_rank_num{
      background: $color_34;
      color: $white;
    }
  }
}


/* =================================
 c_cat
 ================================= */
 // c_cat_1
 .c_cat_1{
  background: $color_6;
  font-size: 1.2rem;
  border-radius: 30px;
  padding: 4px 8px;
  display: inline-flex;;
  justify-content: center;
  align-items: center;
  font-weight: 700;
}

// c_cat_2
.c_cat_2{
  font-size: 1.0rem;
  font-weight: 700;
}
// c_cat_3
.c_cat_3 {
  position: relative;
  background: $white;
  border: 1px solid $color_2;
  display : inline-flex;
  justify-content: center;
  align-items: center;
  padding: 12px 30px;
  transition: $all;
  margin-right: 10px;
  margin-bottom: 10px;
  .c_cat_3_txt{
    color: $color_2;
    font-size: 1.4rem;
    font-weight: bold;
  }
  &.full{
    display: flex;
    width: 100%;
  }
  &.current{
    background: $color_13;
    border: 1px solid $color_13;
  }
  &.yellow{
    background: $color_sub_2;
    border: 1px solid $color_sub_2;
  }
  &.size_S{
    padding: 6px 8px;
  }
  &.size_SS{
    .c_cat_3_txt{
      font-size: 1.2rem;
      line-height: 1.2;
    }
    padding: 6px;
  }
  &.color_6{
    border: 1px solid $color_6;
    .c_cat_3_txt{
      color: $color_6;
    }
  }
  &.color_yellow{
    border: 1px solid $color_sub_2;
    background: $color_sub_2;
    .c_cat_3_txt{
      color: $color_1;
    }
  }
  &.color_gray{
    border: 1px solid $color_26;
    background: $color_26;
    .c_cat_3_txt{
      color: $white;
    }
  }
    &.mb0{
    margin-bottom: 0;
  }
  @include sp {
    font-size: 1.4rem;
  }
}
// c_cat_3 hover
.hover{
  a.c_cat_3:hover{
    opacity: 0.5;
  }
}

/* =================================
 js-toggle_btn_sp 
 ================================= */
 .js-toggle_btn_sp__wrap{
  border: 2px solid $color_25;
  padding: 0 15px;
  @include pc_tab{
    border: 2px solid $white;
    padding: 0;
  }
}
.js-toggle_btn_sp{
  transition: $all;
  @include pc_tab{
    display: none;
  }
}
.js-toggle_sp{
  transition: $all;
  @include sp{
    display: none;
  }
}
/* =================================
 item
 ================================= */
 // c_item_1
 .c_item_1 {
  position: relative;
  font-size:1.4rem;
  &:before{
    content:"“";
  }
  &:after{
    content:"”";
  }
}

// c_item_2
.c_item_2{
  position: relative;
  z-index: 1;
  width: 100%;
  padding: 30px;
  background: $color_13;
  .movie{
    position: relative;
    width: 100%;
    padding-top: 56.25%;
    iframe{
      position: absolute;
      top: 0;
      right: 0;
      width: 100%;
      height: 100%;
    }
  }
  .txt{
    margin-top: 15px;
    font-size: 1.6rem;
    line-height: 1.75;
    position: relative;
    &:before {
      content:"▲　";
    }
  }
  @include sp {
    padding: 15px;
  }
}


// c_item_3
.c_item_3 {
  display : inline-flex;
  align-items: flex-start;
  font-size: 1.4rem;
  font-weight: 700;
  font-family: $font_2;
  .c_item_3_ttl{
    background: $color_1;
    color: $white;
    padding: 0 4px;
    line-height: 1.4;
    margin-right: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .c_item_3_txt{
    flex:1;
    margin-right: 4px;
    margin-top: -2px;
  }
  .c_item_3_txt_sub{
    margin-top: auto;
  }
  @include sp {
    font-size: 1.3rem;
  }
}

 // c_item_4
 .c_item_4 {
  position: relative;
  font-size:1.8rem;
  background: $color_21;
  padding: 16px 20px;
  color: $white;
  font-family: $font_2;
  text-align: center;
}

// c_item_bnr
.c_item_bnr{
  background: $white;
  border: 1px solid $color_5;
  display: block;
  padding: 8px 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: $all;
  @include sp {
    padding: 6px 16px;
    img{
      width: 100%;
    }
  }
}
//h_hover
.hover{
  .c_item_bnr:hover{
    opacity: 50%;
    transition: $all;
  }
}
// c_item_archievement_wrap
.c_item_archievement_wrap{
  border: 2px solid $color_5;
}
// c_item_archievement
.c_item_archievement{
  width: 100%;
  font-size: 1.8rem;
  display: flex;
  color: $color_2;
  .left , .right{
    padding: 20px 16px;
  }
  .left{
    background: $white;
    width: 64%;
    .ttl{
      font-family: $font_2;
      margin-top: 8px;
      .sub{
        font-size: 1.2rem;
        display: inline-block;
      }
    }
  }
  .right{
    background: $color_8;
    width: 36%;
    display: flex;
    align-items: flex-start;
    flex-direction : column;
    .sub{
      font-size: 1.0rem;
    }
    .ttl{
      font-family: $font_2;
      margin-top: 8px;
    }
  }
  .cat_wrap{
    display: flex;
    align-items: center;
  }
  .c_cat_2{
    margin-left: 8px;
  }
  &:last-child{
    .left{
      border-top: 2px solid $color_5;
    }
    .right{
      border-top: 2px solid $white;
    }  
  }
  @include sp {
    font-size: 1.6rem;
    .left , .right{
      padding: 13px;
    }
    .left{
      .ttl{
        line-height: 1.4;
        .sub{
          display: inline-block;
          line-height: 1.4;
          margin-top: 8px;
        }
      }
    }
  }

  &.p_detail{
    font-size: 2.4rem;
    line-height: 1.25;
    .right{
      justify-content: center;
      .ttl{
        font-size: 2.2rem;
      }
    }
  }

}
/* =================================
 c_item
 ================================= */
 .c_item_scroll{
  position: relative;
  font-family: $font_2;
  font-size:1.8rem;
  color: $color_3;
  margin-bottom: 10px;
  padding-left: 42px;
  &:before{
    content:"";
    background: url(../../assets/img/common/scroll.svg);
    background-repeat: no-repeat;
    background-size: contain;
    width: 31px;
    height: 38px;
    display: block;
    position: absolute;
    top: -6px;
    left: 0;
  }
}

/* =================================
 card
 ================================= */
 // c_card_1
 .c_card_1{
  width: 100%;
  height: 100%;
  border: 1px solid $color_5;
  display: block;
  transition: $all;
  background: $white;
  .cont{
    display: flex;
    flex-direction : column;
    align-items: flex-start;
    padding: 20px;
    height: calc(100% - 213px);
  }
  .pic{
    overflow: hidden;
    position: relative;
    height: 213px;
  }
  .pic img{
    position: absolute;
    top: 50%;
    left: 50%;
    width: auto;
    min-width: 100%;
    height: auto;
    min-height: 100%;
    -webkit-transform: translateX(-50%) translateY(-50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    -ms-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    height: 213px;
    width: 213px;
    object-fit: cover;
    -webkit-backface-visibility: hidden;
  }
  .ttl{
    display: flex;
    align-items: center;
    font-family: $font_2;
    font-size: 2.2rem;
    color: $color_2;
    .num{
      margin-right: 8px;
      width: 61px;
      img{
        width: 100%;
      }
      @include tab_content{
        width: 40px;
      }
      @include sp{
        width: 61px;
      }
    }
    @include tab_content{
      font-size: 1.8rem;
    }
    &.flex_ai_start{
      align-items: flex-start;
    }
  }
  &.reason{
    .ttl{
      height: 78px;
    }
  }
  .ttl_2{
    flex:1;
    white-space: nowrap;
  }
  .txt{
    margin-top: 10px;
    margin-bottom: 15px;
    font-size: 1.4rem;
    &.mb0{
      margin-bottom: 0;
    }
  }
  [class^="c_btn"]{
    margin-top: auto;
  }
  @include sp{
    .txt{
      margin-top: 10px;
      margin-bottom: 15px;
    }
    .ttl{
      height: auto;
      font-size: 2.0rem;
    }
  }
}

.hover{
  a.c_card_1:hover{
    opacity: 50%;
  }
}

// c_card_2
.c_card_2{
  width: 100%;
  height: 100%;
  max-width: 368px;
  display: block;
  transition: $all;
  &.full{
    max-width: 100%;
  }
  .cont{
    display: flex;
    flex-direction : column;
    align-items: flex-start;
    padding: 10px 0;
    height: calc(100% - 213px);
    .c_item_3{
      &.last{
        margin-bottom: 16px;
      }
    }
  }
  .pic{
    overflow: hidden;
    position: relative;
    height: 213px;
    width: 100%;
  }
  .pic img{
    position: absolute;
    top: 50%;
    left: 50%;
    width: auto;
    min-width: 100%;
    height: auto;
    min-height: 100%;
    -webkit-transform: translateX(-50%) translateY(-50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    -ms-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    width: 350px;
    height: 213px;
    object-fit: cover;
    -webkit-backface-visibility: hidden;
  }
  .ttl{
    display: flex;
    align-items: center;
    font-family: $font_2;
    font-size: 1.8rem;
    margin-bottom: 10px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    height: 63px;
    &.size_L{
      font-size: 2.0rem
    }
    &.blue{
      color: $color_2;
    }
  }
  .txt{
    margin-top: 20px;
    font-size: 1.4rem;
  }
  [class^="c_btn"]{
    margin-top: auto;
    margin-left: auto;
    &.blue{
      color: $color_3;
    }
  }
  .c_item_3{
    margin-bottom: 8px;
  }
  @include sp{
max-width: 100%
  }
}

.hover{
  a.c_card_2:hover{
    opacity: 50%;
  }
}

// c_card_3
.c_card_3{
  height: 100%;
  width: 100%;
  display: block;
  transition: $all;
  background: $white;
  position: relative;
  border-top: 3px solid $color_6;
  padding: 27px;
  .ttl_wrap{
    display: flex;
    align-items: center;
  }
  .pic{
    overflow: hidden;
    position: relative;
    width: 92px;
    height: 92px;
    margin-right: 20px;
  }
  .pic img{
    position: absolute;
    top: 50%;
    left: 50%;
    width: auto;
    min-width: 100%;
    height: auto;
    min-height: 100%;
    -webkit-transform: translateX(-50%) translateY(-50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    -ms-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    width: 92px;
    height: 92px;
    object-fit: cover;
    -webkit-backface-visibility: hidden;
  }
  .ttl{
    flex:1;
    color: $color_3;
    font-family: $font_2;
    font-size: 2.6rem;
  }
  .txt{
    margin-top: 20px;
    font-size: 1.6rem;
    color: $black;
  }
  @include sp{
    padding: 20px;
    line-height: 1.5;
    .ttl_wrap{
      align-items: flex-start;
    }
    .ttl{
      font-size: 2.4rem;
    }
  }
}

.hover{
  a.c_card_3:hover{
    opacity: 50%;
  }
}


// c_card_4
.c_card_4{
  width: 100%;
  display: flex;
  transition: $all;
  padding-bottom: 42px;
  position: relative;
  font-family: $font_2;
  &:before , &:after  {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    height: 2px;
    width: 100%;
    background: $color_13;
    z-index: 2;
  }
  &:after  {
    width: 252px;
    background: $color_3;
    z-index: 3;
  }
  .cont{
    display: flex;
    flex-direction : column;
    align-items: flex-start;
    flex:1;
    .c_item_3{
      &.last{
        margin-bottom: 16px;
      }
    }
  }
  .pic{
    overflow: hidden;
    position: relative;
    width: 252px;
    height: 165px;
    margin-right: 33px;
  }
  .pic img{
    position: absolute;
    top: 50%;
    left: 50%;
    width: auto;
    min-width: 100%;
    height: auto;
    min-height: 100%;
    -webkit-transform: translateX(-50%) translateY(-50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    -ms-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    width: 252px;
    height: 165px;
    object-fit: cover;
    -webkit-backface-visibility: hidden;
  }
  .info_wrap{
    display: flex;
    .info{
      flex:1;
    }
    .info_wrap_num{
      color: rgba(234, 203, 82, 0.4);
      position: absolute;
      right: 0;
      top: 0;
      width: 180px;
      font-size: 8.5rem;
      line-height: 1;
      margin-top: -20px;
      letter-spacing: -0.85px;
      &:before{
        content:"#";
        font-size: 5.5rem;
        margin-right: 10px;
      }
      &.new{
        &:before{
          font-size: 8.5rem;
        }
      }
    }
  }
  .ttl{
    display: flex;
    align-items: center;
    font-size: 2.0rem;
    margin-bottom: 10px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    padding-right: 200px;
  }
  .txt{
    margin-top: 20px;
    font-size: 1.4rem;
  }
  [class^="c_btn"]{
    margin-top: auto;
    margin-left: auto;
  }
  .c_item_3{
    margin-bottom: 8px;
  }
  @include sp{
    max-width: 100%;
    flex-direction: column;
    padding-bottom: 56px;
    &:after  {
      width: 50%;
    }
    .pic{
      width: 100%;
      height: 220px;
      margin-right: 0;
    }
    .info_wrap{
      flex-direction: column;
      .info_wrap_num{
        width: auto;
        font-size: 5.2rem;
        position: absolute;
        bottom: 10px;
        line-height: 1;
        left: 0;
        top: calc(100% - 48px);
        &:before{
          content:"#";
          font-size: 3.4rem;
          margin-right: 10px;
        }
        &.new{
          &:before{
            font-size: 3.4rem;
          }
        }
      }
    }
    .ttl{
      font-size: 1.8rem;
      margin-top: 10px;
      margin-bottom: 10px;
      padding-right: 0;
    }
    [class^="c_btn"]{
      margin-top: auto;
      margin-left: auto;
      position: absolute;
      bottom: 24px;
      right: 0;
    }

  }
}

.hover{
  a.c_card_4:hover{
    opacity: 50%;
  }
}

// c_card_5
.c_card_5{
  display: flex;
  margin-top: 60px;
  &:first-child{
    margin-top: 0;
  }
  .cont{
    flex:1;
    margin-right: 60px;
    display: flex;
    flex-direction : column;
    align-items: flex-start;
  }
  [class^="c_ttl"]{
    width: 100%;
  }
  [class^="c_btn"]{
    margin-top: auto;
  }
  [class^="c_pic"]{
    width: 530px;
  }
  @include sp{
    flex-direction: column-reverse;
    margin-top: 70px;
    .cont{
      margin-right: 0;
    }
    .c_ttl_6{
      margin-top: 20px;
    }
    [class^="c_pic"]{
      width: 100%;
    }
  }
}
// c_card_6
.c_card_6{
  padding: 30px;
  background: $white;
  font-size: 1.6rem;
  border: 2px solid $color_13;
  .pic{
    position: relative;
    border-radius: 50%;
    width: 90px;
    height: 90px;
    overflow: hidden;
    margin-right: 20px;
    img{
      position: absolute;
      top: 50%;
      left: 50%;
      width: auto;
      min-width: 100%;
      height: auto;
      min-height: 100%;
      -webkit-transform: translateX(-50%) translateY(-50%);
      transform: translateX(-50%) translateY(-50%);
      width: 90px;
      height: 90px;
      object-fit: cover;
      -webkit-backface-visibility: hidden;
    }
  }
  .info_wrap{
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
  .info{
    flex:1;
    color: $color_2;
    .info_sub{
      font-size: 1.4rem;
    }
    .info_ttl{
      font-family: $font_2;
    }
  }
  @include sp{
    padding: 15px;
  }
}



// c_card_7
.c_card_7{
  color: $color_2;
  font-size: 1.3rem;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  display:         flex;
  position: relative;
  transition: $all;
  .pic{
    overflow: hidden;
    position: relative;
    width: 122px;
    height: 80px;
    margin-right: 10px;
  }
  .pic img{
    position: absolute;
    top: 50%;
    left: 50%;
    width: auto;
    min-width: 100%;
    height: auto;
    min-height: 100%;
    -webkit-transform: translateX(-50%) translateY(-50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    -ms-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    width: 122px;
    height: 80px;
    object-fit: cover;
    -webkit-backface-visibility: hidden;
  }
  .cont{
    flex:1;
    margin-top: -4px;
    color: $color_2;
  }
}

.hover {
  a.c_card_7:hover{
    opacity: 0.5
  }
}



// c_card_8
.c_card_8{
  color: $color_2;
  position: relative;
  transition: $all;
  display: table;
  clear: both;
  width: 100%;
  &:last-child{
    margin-bottom: 0;
  }

  .pic{
    float: left;
  }

  .c_card_8_ttl , .cont{
    margin-left: 258px;
    width: calc(100% - 258px);
  }
  .c_card_8_ttl{
    border-bottom: 2px solid $color_13;
    padding-bottom: 12px;
    margin-bottom: 12px;
  }
  .pic{
    overflow: hidden;
    position: relative;
    width: 238px;
    height: 155px;
    margin-right: 20px;
    img{
      position: absolute;
      top: 50%;
      left: 50%;
      width: auto;
      min-width: 100%;
      height: auto;
      min-height: 100%;
      -webkit-transform: translateX(-50%) translateY(-50%);
      -moz-transform: translateX(-50%) translateY(-50%);
      -ms-transform: translateX(-50%) translateY(-50%);
      transform: translateX(-50%) translateY(-50%);
      backface-visibility: hidden;
      -webkit-backface-visibility: hidden;
      width: 238px;
      height: 155px;
      object-fit: cover;
      -webkit-backface-visibility: hidden;
    }
  }
  .c_list_flex_2_item{
    margin-right: 20px;
    margin-bottom: 20px;
  }
  &.size_L{
    .pic{
      width: 238px;
    }
    .c_card_8_ttl , .cont{
      margin-left: 253px;
      width: calc(100% - 253px);
    }
  }
  @include h_change {
    .c_card_8_ttl_wrap{
      display: flex;
      align-items: center;
      border-bottom: 1px solid $color_13;
      padding-bottom: 20px;
    }
    .c_card_8_ttl{
      flex:1;
      margin-left: auto;
      border-bottom: none;
      padding-bottom: 0;
      margin-bottom: 0;
    }
    .cont{
      margin-left: 0;
      width: 100%;
      margin-top: 15px;
    }
    .pic{
      float: none;
      width: 128px;
      height: 83px;
      img{
        width: 128px;
        height: 83px;
        object-fit: cover;
        -webkit-backface-visibility: hidden;
      }
    }
    .c_list_flex_2_item{
      &:nth-last-child(-n+2){
        margin-bottom: 1px;
      }
    }
    &.size_L{
      .c_card_8_ttl{
        flex:1;
        margin-left: auto;
        border-bottom: none;
        padding-bottom: 0;
        margin-bottom: 0;
      }
      .cont{
        margin-left: 0;
        width: 100%;
        margin-top: 15px;
      }
      .pic{
        float: none;
        width: 128px;
        height: 83px;
        img{
          width: 128px;
          height: 83px;
          object-fit: cover;
          -webkit-backface-visibility: hidden;
        }
      }
    }
  }
}
.hover {
  a.c_card_8:hover{
    opacity: 0.5
  }
}

//c_card_9
.c_card_9{
  font-size: 1.4rem;
  padding: 20px 10px;
  border-bottom: 1px solid $color_8;
  .info{
    display: flex;
    align-items: center;
    .date{
      font-size: 1.2rem;
      font-weight: bold;
      margin-right: 8px;
    }
    .time{
      font-size: 1.2rem;
      font-weight: bold;
      color: $color_6;
    }
    .c_cat_3{
      margin-right: 0;
      margin-bottom: 0;
    }
  }
  .c_link_2{
    margin-top: 12px;
  }
  @include sp {
  .c_link_2{
    margin-top: 0;
  }
  }
}


//c_card_10
.c_card_10{
  display: flex;
  flex-wrap : wrap;
  font-size: 1.6rem;
  .col{
    width: 50%;
    display: flex;
    flex-direction : column;
    &.left{
      border-right: 2px solid $white;
    }
    .ttl_1{
      padding: 20px 40px;
      background: $color_25;
      color: $white;
      text-align: center;
      font-weight: bold;
      &.bg_light{
        background: $color_26;
      }
    }
  }
  .cont{
    padding: 40px;
    background: $color_5;
    height: 100%;
    border-bottom: 2px solid $white;
  }
    @include sp {
      .col{
        width: 100%;
        .ttl_1{
          padding: 20px 15px;
        }
      }
      .cont {
        padding: 20px 15px;
      }
  }
}


//c_card_11
.c_card_11{
  display: block;
  border-bottom: 2px solid $color_5;
  transition: $all;
  position: relative;
  height: calc(100% - 80px);
  .pic{
    overflow: hidden;
    position: relative;
    height: 150px;

    .img{
      position: absolute;
      top: 50%;
      left: 50%;
      width: auto;
      min-width: 100%;
      height: auto;
      min-height: 100%;
      -webkit-transform: translateX(-50%) translateY(-50%);
      transform: translateX(-50%) translateY(-50%);
      width: 350px;
      height: 150px;
      object-fit: cover;
      -webkit-backface-visibility: hidden;
    }
  }
  .cont{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px 0;
  }
  .ttl{
    display: flex;
    align-items: center;
    font-family: $font_2;
    font-size: 1.8rem;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }
  .date{
    color: $color_2;
    font-size: 1.4rem;
    margin-top: 14px;
    font-weight: bold;
  }
  .c_table_1{
    margin-top: 10px;
    .th , .td{
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      word-break: break-all
    }
    .th{
      width: 30%;
    }
    .td{
      width: 70%;
      max-width: 0;
    }
  }
  .c_list_1_size_s{
    margin-top: 10px;
    .c_list_1_item{
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    }
  }
  .state{
    width: 82px;
    height: 82px;
    font-size: 1.4rem;
    font-weight: bold;
    color: $color_21;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 4;
    display: flex;
    justify-content: center;
    align-items: center;
    &:before{
      content:"";
      position: absolute;
      top: 0;
      left: 0;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 82px 82px 0 0;
      border-color: $color_sub_2 transparent transparent transparent;
      z-index: -1;
    }
    &.end{
      color: $white;
      &:before{
        border-color: $color_26 transparent transparent transparent;
      }
    }
    &.dateheld{
      color: $white;
      &:before{
        border-color: $color_36 transparent transparent transparent;
      }
    }
    .inner{
      transform: rotate(-45deg);
      display: inline-block;
      margin-left: -24px;
      margin-top: -24px;
    }
  }

  @include sp{
    height: auto;
    .cont{
      padding: 10px 0 15px;
    }

    .ttl{
      font-size: 1.6rem;
    }
  }
}
.hover a.c_card_11:hover{
  opacity: 0.5
}

// c_card_12
.c_card_12{
  display: flex;
  .ttl{
    width: 30%;
    font-size: 1.6rem;
    font-weight: bold;
    padding-right: 40px;
  }
  .cont{
    flex:1;
    font-size: 1.6rem;
  }
}

// c_card_13
.c_card_13{
  color: $color_2;
  position: relative;
  transition: $all;
  width: 100%;
  &:last-child{
    margin-bottom: 0;
  }
  .c_card_13_ttl{
    border-bottom: 2px solid $color_13;
    padding-bottom: 12px;
    margin-bottom: 12px;
  }
  .c_list_c_card_13{
    display: flex;
    flex-wrap : wrap;
    .item{
      margin-right: 30px;
    }
  }
  @include sp {
  }
}
// c_card_14
.c_card_14{
  .ttl{
    font-family: $font_2;
    font-weight: bold;
    font-size: 2.6rem;
    margin-bottom: 20px;
  }
  .txt{
    font-size: 1.6rem;
  }
}
//c_card_teacher
.c_card_teacher{
  display: flex;
  flex-wrap : wrap;
  flex-direction: row-reverse;
  justify-content: space-between;
  margin-top: 20px;
  .pic{
    width: 32.5%;
    .img{
      width: 100%;
    }
  }
  .cont_wrap{
    width: calc(67.5% - 40px);
    .cont{
      margin-top: 40px;
      &:first-child{
        margin-top: 0;
      }
      &.mt_S{
        margin-top: 20px;
      }
      .ttl{
        font-size: 1.8rem;
        font-family: $font_2;
        margin-bottom: 15px;
        font-weight: bold;
      }
      .txt{
        font-size: 1.6rem;
      }
    }
  }
  @include sp{
    .pic , .cont_wrap{
      width: 100%;
    }
    .cont_wrap{
      .cont{
        margin-top: 30px;
        &:first-child{
          margin-top: 20px;
        }
        &.mt_S{
          margin-top: 30px;
        }
      }
    }
  }
}

/* =================================
 c_article
 ================================= */
 // c_article_left
 .c_article_left{
  &:after{
    content: "";
    display: table;
    clear: both;
  }
  .c_pic_M , .c_pic_S{
    float: left;
    margin-right: 40px;
    margin-bottom: 20px;
  }
  @include sp{
    .c_pic_M , .c_pic_S{
      margin-right: 10px;
      margin-bottom: 10px;
    }
  }
}
// c_article_right
.c_article_right{
  &:after{
    content: "";
    display: table;
    clear: both;
  }
  .c_pic_M , .c_pic_S{
    float: right;
    margin-left: 40px;
    margin-bottom: 20px;
  }
  @include sp{
    .c_pic_M , .c_pic_S{
      margin-left: 10px;
      margin-bottom: 10px;
    }
  }
}


/* =================================
 c_tel_box
 ================================= */
 .c_tel_box{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}
.c_tel_num{
  font-family:$font_2;
  font-size: 3.6rem;
  color: $white;
  margin-left: 20px;
  letter-spacing: 0.96px;
  text-align: left;
  .num{
    letter-spacing: 2.88px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .ico{
    width: 24px;
    height: 28px;
    margin-right: 4px;
    img{
      width: 100%;
      display: block;
    }
  }
  .u_txt_4{
    margin-top: 0;
  }
}
/* =================================
 pager
 ================================= */
 .c_pager{
  display: flex;
  align-items: center;
  justify-content: center;
  @include sp {
    justify-content: space-between;
  }
}
.c_pager_num__side ,
.c_pager_num  {
  position: relative;
  font-family:$font_2; 
  color: $color_3;
  font-size: 1.6rem;
  min-width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: $all;
  background: $white;
  @include sp {
    min-width: 27px;
    height: 27px;
  }
}
.c_pager_num{
  margin: 0 4px;
  &.current{
    background: $color_3;
    color: $white;
  }
}
.c_pager_first_2 , .c_pager_last{
  min-width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  @include sp {
    min-width: 27px;
    height: 27px;
  }
}
.c_pager_num__side{
  border: 1px solid $color_3;
  &.c_pager_first{
    margin-right: 10px;
  }
  &.c_pager_prev{
    margin-right: 30px;
  }
  &.c_pager_last{
    margin-left: 10px;
  }
  &.c_pager_next{
    margin-left: 30px;
  }
  &.c_pager_first , 
  &.c_pager_prev , 
  .c_pager_first_2 ,
  &.c_pager_next , 
  &.c_pager_last , 
  .c_pager_last_2{
    &:before , &:after{
      content: "";
      position: absolute;
      left: 16px;
      top: 9px;
      width: 10px;
      height: 10px;
      border-left: 1px solid $color_3;
      border-bottom: 1px solid $color_3;
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }
  &.c_pager_next , 
  &.c_pager_last , 
  .c_pager_last_2{
    &:before , &:after{
      -webkit-transform: rotate(-135deg);
      transform: rotate(-135deg);
    }
  }
  &.c_pager_prev{
    &:before , &:after{
      left: 12px;
    }
  }
  .c_pager_first_2{
    &:before , &:after{
      left: 10px;
    }
  }
  &.c_pager_next{
    &:before , &:after{
      left: 8px;
    }
  }
  .c_pager_last_2{
    &:before , &:after{
      left: 4px;
    }
  }
  &.c_pager_last {
    &:before , &:after{
      left: 10px;
    }
  }
  &.none{
    border: 1px solid rgba($color_3 , 0.2);
    &.c_pager_first , 
    &.c_pager_prev , 
    .c_pager_first_2 ,
    &.c_pager_next , 
    &.c_pager_last , 
    .c_pager_last_2{
      &:before , &:after{
        border-left: 1px solid rgba($color_3 , 0.2);
        border-bottom: 1px solid rgba($color_3 , 0.2);
      }
    }
  }
  @include sp {
    &.c_pager_first{
      margin-right: 4px;
    }
    &.c_pager_prev{
      margin-right: 10px;
    }
    &.c_pager_last{
      margin-left: 4px;
    }
    &.c_pager_next{
      margin-left: 10px;
    }
    &.c_pager_first , 
    &.c_pager_prev , 
    .c_pager_first_2 ,
    &.c_pager_next , 
    &.c_pager_last , 
    .c_pager_last_2{
      &:before , &:after{
        top: 7px;
      }
    }
    &.c_pager_prev{
      &:before , &:after{
        left: 11px;
      }
    }
    .c_pager_first_2{
      &:before , &:after{
        left: 8px;
      }
    }
    &.c_pager_next{
      &:before , &:after{
        left: 6px;
      }
    }
    .c_pager_last_2{
      &:before , &:after{
        left: 1px;
      }
    }
    &.c_pager_last {
      &:before , &:after{
        left: 9px;
      }
    }
  }
}

a[class^="c_pager"]{
  color: $color_3;
}

.hover{
  a[class^="c_pager_num"]:hover{
    background: $color_3;
    color: $white;
    &.c_pager_first , 
    &.c_pager_prev , 
    .c_pager_first_2 ,
    &.c_pager_next , 
    &.c_pager_last , 
    .c_pager_last_2{
      &:before , &:after{
        border-left: 1px solid $white;
        border-bottom: 1px solid $white;
      }
    }
  }
}
/* =================================
 hoge
 ================================= */
 .c_hoge {
 }
/* ======================================================================
 c_f_content_size
 ====================================================================== */
 .c_f_content_size {
  position: relative;
  padding: 0;
  margin: 0 auto;
  width: 100%;
  max-width: $content_width;
  display: flex;
  padding: 60px 0;

  @include tab_content{
    flex-direction: column-reverse;
    padding: 60px;
  }
  @include sp{
    flex-direction: column;
    padding: 20px $sp_side_padding;
  }
}
/* ======================================================================
 c_f_left
 ====================================================================== */
 .c_f_left{
  .c_btn_1{
    margin-top: 32px;
  }
  .c_f_logo_area{
    position: absolute;
    top: 60px;
    left: 0;

  }
  @include pc_tab{
    flex-direction: column-reverse;
    display: flex;
    width: 300px;
  }
  @include tab_content{
    .c_f_logo_area{
      position: static;
    }
  }
  @include sp{
    margin-top: 40px;
    .c_f_logo_area{
      position: static;
      margin-top: 50px;
    }
  }
}

// c_f_subNav
.c_f_subNav{
  display: flex;
  flex-wrap : wrap;
  position: absolute;
  bottom: 60px;
  left: 0;
  .c_f_subNav_item{
    margin-right: 30px;
  }
  @include tab_content{
    margin-top: 24px;
    position: static;
  .c_f_subNav_item{
    margin-bottom: 8px;
  }
  }
  @include sp{
    position: static;
    li{
      margin-top: 24px;
    }
  }
}

// c_f_main_logo
.c_f_main_logo{
  width: 100%;
  max-width: 140px;
  display: block;
  img{
    width: 100%;
  }
}
//h_hover
.hover{
  .c_f_main_logo:hover{
    opacity: 50%;
  }
}

/* ======================================================================
 c_f_link_area
 ====================================================================== */


 // c_f_lsit_item
 .c_f_link_area{
  @include pc_tab{
    padding-bottom: 80px;
    display: flex;
    flex:1;
    order: 2;
    .c_f_lsit{
      width: 22%;
      &.wide{
        flex:1;
      }
    }
  }
    @include h_change {
    .c_f_lsit{
      width: 25%;
      &.wide{
        flex: 25%;
      }
    }
    }
    @include sp {
    .c_f_lsit{
      width: 100%;
      &.wide{
        flex: 100%;
      }
    }
    }
}
// c_f_lsit_item
.c_f_lsit_item{
  font-size: 1.4rem;
  font-weight: 700;
  padding: 10px 0;
  display: block;
  position: relative;
  transition: $all;
  @include sp {
    border-bottom: 2px solid $color_5;
    padding: 20px;
    padding-left: 0;
    &.is-menuToggle {
      &:after, &:before {
        content: "";
        position: absolute;
        transition: $all;
        top: 50%;
        right: 0;
        width: 16px;
        height: 2px;
        background: #222;
        transform:translate(0 , -50%);
      }
      &:before {
        top: 50%;
        right: 7px;
        width: 2px;
        height: 16px;
      }
    }
    &.active:hover {
      &:before {
        transform:translate(0,-50%) rotate(90deg);
      }
    }
  }

}


// c_f_lsit_item_2
.c_f_lsit_item_2{
  position: relative;
  font-size: 1.2rem;
  padding: 8px 0;
  display: block;
  padding-left: 20px;
  transition: $all;
  &.outside{
    &:after {
      content: "";
      background: url(../img/common/ico_tab_dark.svg);
      background-repeat: no-repeat;
      background-size: 12px;
      background-position: center;
      display: inline-block;
      width: 12px;
      height: 18px;
      border-top: none;
      border-right: none;
      -webkit-transform: none;
      transform: none;
      margin-left: 4px;
      position: absolute;
    }
  }
  &:before {
    content: "";
    position: absolute;
    left: 8px;
    top: 13px;
    width: 8px;
    height: 8px;
    border-top: 2px solid $color_5;
    border-right: 2px solid $color_5;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  &.size_M{
    font-size: 1.4rem;
    padding: 16px 0;
    padding-left: 20px;
    &:before {
      content: "";
      position: absolute;
      left: 8px;
      top: 23px;
    }
  }
  @include sp {
    padding: 16px 0;
    padding-left: 20px;
    &:before {
      top: 22px;
    }
  }
}

.hover{
  .c_f_lsit_item , .c_f_lsit_item_2{
    &:hover {
      opacity: 0.5;
    }
  }
}

// c_f_lsit_sub
.c_f_lsit_sub{
  .c_btn_14{
    padding: 8px 0;
    padding-left: 14px;
    margin-left: 16px;
    &:before{
      top: 16px;
    }
  }
  @include sp {
    display: none;
  }
}
// c_footer_copylight
.c_footer_copylight{
  font-size: 1.0rem;
  margin-top: 32px;
  @include pc_tab{
    position: absolute;
    right: 0;
    bottom: 60px;
  }
  @include tab_content{
    right: 20px;
  }
}




//c_item_anchorNav
.c_item_anchorNav{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap : wrap;
  @include sp{
    &.sp_column{
      flex-direction : column;
      align-items: flex-start;
      .c_item_anchorNav_item{
        margin-top: 18px;
        &:first-child{
          margin: 0;
        }
        &:after {
          content: "";
          width: 2px;
          height: 25px;
          background: #C2AF63;
          position: absolute;
          -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
          top: 50%;
          right: 0;
        }
      }
    }
  }
}
//c_item_anchorNav_item
.c_item_anchorNav_item{
  position: relative;
  padding: 0 30px;
  padding-right: 46px;
  &:before{
    content:"";
    width: 2px;
    height: 25px;
    background: $color_6;
    position: absolute;
    transform:translate(0 , -50%);
    top: 50%;
    left: 0;
  }
  &:last-child{
    &:after{
      content:"";
      width: 2px;
      height: 25px;
      background: $color_6;
      position: absolute;
      transform:translate(0 , -50%);
      top: 50%;
      right: 0;
    }
  }
  @include sp{
  padding: 0 10px;
  padding-right: 28px;
  }
}
//c_item_anchor
.c_item_anchor{
  font-family: $font_2;
  position: relative;
  font-size: 1.7rem;
  color: $color_3;
  font-weight: bold;
  transition: $all;
  &:after{
    content: "";
    position: absolute;
    top: 8px;
    right: -16px;
    width: 8px;
    height: 8px;
    border-top: 2px solid $color_3;
    border-right: 2px solid $color_3;
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg);
    -webkit-transition: 0.3s all;
  }
  @include sp{
    font-size: 1.4rem;
    &:after{
      top: 6px;
    }
  }
}
a.c_item_anchor{
  color: $color_3;
}
.hover{
  .c_item_anchor:hover{
    opacity: 0.5
  }
}

/* ======================================================================
 c_slideshare
 ====================================================================== */
 .c_slideshare  {
  position: relative;
  z-index: 1;
  width: 100%;
  .iframe{
    margin: 0 auto;
    display: block;
    width: 100%;
    height: 450px;
  }
  @include sp{
    .iframe{
      height: 188px;
    }
  }
}
/* ======================================================================
 c_youtube
 ====================================================================== */
 .c_youtube  {
  position: relative;
  z-index: 1;
  width: 100%;
  .iframe{
    margin: 0 auto;
    display: block;
    width: 100%;
    height: 450px;
  }
  @include sp{
    .iframe{
      height: 188px;
    }
  }
}

/* ======================================================================
 c_map
 ====================================================================== */

 .c_map_wrap{
  display: flex;
  .map{
    width: 50%;
    margin-right: 40px;
    height: 0;
    overflow: hidden;
    padding-bottom: 38%;
    position: relative;
    .iframe{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
  .cont{
    flex:1;
    font-size: 1.6rem;
    .c_btn_3{
      margin-top: 15px;
    }
  }
  @include sp{
    flex-direction : column;
    .map{
      width: 100%;
      padding-bottom: 73%;
    }
    .cont{
      margin-top: 20px;
    }
  }
}


/* ======================================================================
 c_bg_1
 ====================================================================== */
 // c_bg_1
 .c_bg_1{
  position: relative;
  background: $color_4;
  z-index: 4;
  padding: 80px 0;
  &.size_M{
    padding: 40px 0;
  }
  @include sp {
    padding: 40px 0;
  }
}
 // c_bg_1b
 .c_bg_1b{
  position: relative;
  background: $color_4;
  z-index: 4;
  padding: 80px 0;
  &.size_M{
    padding: 40px 0;
  }
  @include sp {
    padding: 50px 0;
  }
}
 // c_bg_2
 .c_bg_2{
  position: relative;
  background: $white;
  z-index: 4;
  padding: 80px 0;
  @include sp {
    padding: 40px 0;
  }
}
/* ======================================================================
 c_txt_marker_1
 ====================================================================== */
 .c_txt_marker_1{
  position: relative;
  background: rgba(211,197,139,0.5);
  z-index: 4;

  background: -webkit-gradient(linear, left top, left bottom, color-stop(16%, transparent), color-stop(0%, #E9E2C5));
  background: -webkit-linear-gradient(transparent 16%, #E9E2C5 0%);
  background: linear-gradient(transparent 16%, #E9E2C5 0%);
}
 .c_txt_marker_2{
  position: relative;
  background: rgba(211,197,139,0.5);
  z-index: 4;
  padding: 1px 5px;
}
/* ======================================================================
 c_case_info
 ====================================================================== */
 .c_case_info{
  .top{
    display: flex;
    justify-content: space-between;
  }
  .ttl_wrap{
    width: calc(50% - 0.5px);
  }
  .ttl , .name{
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction : column;
  }
  .ttl{
    font-family: $font_2;
    width: 100%;
    padding: 2px 8px;
    font-size: 1.4rem;
    color: $white;
    background: $color_25;
    &.bg_light{
      background: $color_26;
    }
  }
  .name{
    font-family: $font_2;
    font-size: 1.8rem;
    background: $color_13;
    color: $color_2;
    padding: 10px;
  }
  .cont{
    border: 2px solid $color_13;
    padding: 30px;
  }
  .c_ttl_12{
    margin-bottom: 10px;
  }
  @include sp{
    .top{
      flex-direction : column;
    }
    .ttl_wrap{
      width: 100%;
    }
    .name{
      font-size: 1.6rem;
    }
    .cont{
      padding: 15px;
    }
  }
}

/* ======================================================================
 c_slick
 ====================================================================== */
 // c_slick
 .c_slick{
  background: $color_4;
  padding: 80px;
  @include sp{
    padding: 60px 0;
  }
}
.c_slick__slider{
  @include pc_tab{
    display: flex;
    padding: 0;
    margin: 0 auto;
    width: calc(100% + 25px);
    overflow: hidden;
    max-width: $content_width;
    .c_slick__slider_item{
      width: 33.33333333%;
      margin-right: 25px;
      &:last-child{
        margin-right: 0;
      }
    }
  }
}


/* ======================================================================
 c_kv__sliderw
 ====================================================================== */
 @include sp{
  .c_kv__sliderw {
    width: calc(100% - 40px);
    margin: 0 auto;
  }
  .c_kv__slider-control {
    margin-top: 3.2rem;
  }
  .c_kv_slider__item{
    width: calc(100% - 2px);
    margin: 0 auto;
  }
  .slick-track {
    display: flex;
  }
  .slick-slide {
    height: auto !important;
  }
}

.c_kv__slider-control{
  display: none;
}
.c_kv_slider__item{
  height: 100%;
}
/*
 KV slider js
 ====================================================================== */

 @include sp{
  .is-respSliderControl,
  .is-spSliderControl {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }


  .is-respSliderControl__left-arr,
  .is-spSliderControl__left-arr {
    font-size: 0;
  }

  .is-respSliderControl__left-arr button,
  .is-spSliderControl__left-arr button {
    width: 100%;
    height: 100%;
  }

  .is-respSliderControl__current,
  .is-spSliderControl__current {
    margin-right: 1rem;
    color: $color_2;
    font-family:$font_2;
    font-size: 2.2rem;
  }


  .is-respSliderControl__dot .slick-dots,
  .is-spSliderControl__dot .slick-dots {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    line-height: 1 !important;
    margin-top: 0 !important;
    padding-left: 0 !important;
  }

  .is-respSliderControl__dot .slick-dots li,
  .is-spSliderControl__dot .slick-dots li {
    width: 4rem;
    height: 3.2rem;
    cursor: pointer;
    position: relative;
    margin-top: 0 !important;
  }

  .is-respSliderControl__dot .slick-dots li:before,
  .is-spSliderControl__dot .slick-dots li:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto 0;
    background: #D8D8D8 !important;
  }

  .is-respSliderControl__dot .slick-dots li.slick-active:before,
  .is-spSliderControl__dot .slick-dots li.slick-active:before {
    background: #C9B55E !important;
  }

  .is-respSliderControl__dot .slick-dots button,
  .is-spSliderControl__dot .slick-dots button {
    font-size: 0;
  }

  .is-respSliderControl__total,
  .is-spSliderControl__total {
    margin-left: 1rem;
    color: $color_15;
    font-family:$font_2;
    font-size: 2.2rem;
  }

  .is-respSliderControl__right-arr,
  .is-spSliderControl__right-arr {
    font-size: 0;
    margin-left: 1rem;
    background:$color_6;
    top: 0;
    right: 0;
    width: 40px;
    height: 40px;
    background: url(../../assets/img/common/slick_next.svg) no-repeat center center/contain;
  }

  .is-respSliderControl__right-arr button,
  .is-spSliderControl__right-arr button {
    width: 100%;
    height: 100%;
  }

  .is-respSliderControl__left-arr,
  .is-spSliderControl__left-arr {
    position: relative;
    font-size: 0;
    background:$color_6;
    width: 40px;
    height: 40px;
    background: url(../../assets/img/common/slick_prev.svg) no-repeat center center/contain;

  }
  .is-respSliderControl__left-arr button,
  .is-spSliderControl__left-arr button {
    width: 100%;
    height: 100%;
  }
}


/* =================================
 c_hr
 ================================= */
 // c_hr_1
 .c_hr_1{
  width: 100%;
  height: 2px;
  background: $color_13;
  margin: 80px auto;
  &.size_S{
    margin: 40px auto;
  }
  @include sp{
    margin: 50px auto;
  }
}
 // c_hr_2
 .c_hr_2{
  width: 2px;
  height: 50px;
  background: $color_6;
  margin: 40px auto 20px;
  &.mt_sizeS{
    margin: 15px auto 20px;
  }
}
/* =================================
 c_indent_1
 ================================= */
.c_indent_1{
  margin-left: 20px;
}