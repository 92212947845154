/* ======================================================================
 layout
====================================================================== */

/* =================================
 l_container
================================= */
.l_container {
  width: 100%;
  overflow: hidden;
}


/*====================================
    l_header
====================================*/
$height_1: 114px;//header
$height_2: 94px;//header scroll
$height_3: 63px;//header sp

.l_header {
  position: relative;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: $height_1;
  background: $color_tp;
  z-index: 5;
  display: flex;
  color: $white;
  transition: $all_2;
  @include sp{
  height: $height_3;
  }
}

.h_logo_1 , .h_logo_2{
  position: relative;
  width: 184px;
  background: $color_tp;
  margin-left: 30px;
  align-self: center;
  transition: $all;
  .img{
    width: 100%;
  }
  @include sp{
    width: 110px;
    margin-left: 20px;
  }
}
.h_logo_2{
  display: none;
}

.h_contact{
  background: $color_sub_1;
  width: 127px;
  height: $height_1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction : column;
  font-family:$font_2;
  transition: $all;
  .ico{
   width: 28px;
   height: 32px; 
  }
  .h_contact_txt{
  color: $white;
  font-size: 1.7rem;
  margin-top: 4px;
  }
  @include h_change{
    display: none;
  }
}
.h_info{
  background: rgba($color_18 , 0.5);
  height: 50%;
  display: flex;
  .h_info_txt{
    color: $white;
    padding: 0 14px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: $all;
    font-size:1.4rem;
  }
  @include h_change{
    display: none;
  }
}
.h_info_list{
    display: flex;
}
.h_tel_num{
  display: flex;
  align-items: center;
  font-family:$font_2;
  font-size: 1.6rem;
  color: $white;
  margin-right: 20px;
  margin-left: 20px;
  letter-spacing: 0.96px;
  text-align: left;
  .num{
    letter-spacing: 1.6px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    margin-top: -4px;
  }
  .ico{
    width: 14px;
    height: 16px;
    margin-right: 4px;
    .img{
      width: 100%;
      display: block;
    }
  }
  .tel_link{
    color: $white;
  }
  .u_txt_4{
    margin-top: 0;
  }
}

.h_drawer {
  background: $color_tp;
  font-size: 1.6rem;
  margin-left: auto;
  display: flex;
  align-items: flex-end;
  flex-direction : column;
}
.h_drawer-wrap {
  height: 50%;
}
.h_drawer-wrap::-webkit-scrollbar {
 display:none;
}

.h_gnav-ttl {
  display: none;
}
.h_gnav_list {
  font-size: 1.6rem;
  display: flex;
  font-weight: bold;
  height: 100%;
  font-family: $font_2;
  .h_gnav_list__txt {
    position: relative;
    display: block;
    overflow: hidden;
    color: $white;
    transition: $all;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 0 24px; 
    &:after{
      content: "";
      width: 1px;
      height: 24px;
      display: block;
      background: $color_19;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translate(0 , -50%);
    }
    &.bdr_none{
      &:after{
        content:none;
      }
      @include h_change{
        &:after{
          content: "";
        }
      }
    }
  }
  .gnav-toggle{
    &.bdn{
      .h_gnav_list__txt{
        &:after{
          content:none;
        }
      }
    }
  }
}
.h_btn-menu {
  display: none;
}

//h_hover
.hover{
  .h_contact:hover , 
  .h_info_txt:hover , 
  .h_gnav_list__txt:hover ,
  .h_logo_1:hover ,
  .h_logo_2:hover{
    opacity: 50%;
  }
}

#overlay{
  display: none;
  cursor: pointer;
}
.drawer-opened #overlay {
  display: block;
  position : fixed;
  z-index : -1;
  top : 0;
  left : 0;
  width : 100%;
  height : 100%;
  transition : $all;
  opacity : 0.2;
  background : #000;
}

@include h_change {
  .l_header{
    height: 64px;
  }
  .h_logo_1{
    width: 130px;
  }
  .h_btn-menu {
    position: relative;
    display: block;
    width: $height_3;
    height: $height_3;
    margin-bottom: 0;
    cursor: pointer;
    text-align: center;
    z-index: 10000;
    margin-left: auto;
    transition: $all;
  }

  .h_btn-bg {
    width: 100%;
    height: 64px;
    margin: 0 auto;
    background: $color_46;
    display: flex;
    flex-direction: column;
    justify-content: center;
    transition: $all;
    .icon-bar {
      display: block;
      height: 2px;
      margin-bottom: 10px;
      margin-left: 14px;
      transition: all .3s ease;
      border-radius: 50px;
      background: $white;
      &:nth-child(1) {
        width: 34px;
      }
      &:nth-child(2) {
        width: 26px;
      }
      &:nth-child(3) {
        width: 18px;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .h_drawer {
    position: absolute;
    top: 0;
    right: 0;
    width: 380px;
    height: 100vh;
    background: $white;
    overflow-y: scroll;
    -webkit-transition: $all;
    transition: $all;
    -webkit-transform: translateX(200%);
    transform: translateX(200%);
    opacity: 0;
    padding: 20px;
    .h_gnav_list {
      flex-direction: column;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      -webkit-align-items: flex-start;
      -ms-flex-align: start;
      align-items: flex-start;
      padding-bottom: 300px;
    }
    .h_gnav_list .gnav-toggle:nth-last-child(-n+2){
      .c_btn_4{
        margin-top: 20px;
        height: 72px;
      }
    }
    .h_gnav_list .gnav-toggle:last-child{
      .c_btn_4{
        margin-bottom: 200px;
      }
    }

  }
  .h_drawer-wrap{
    width: 100%;
  }
  .h_gnav_list{
    .gnav-toggle{
      width: 100%;
      position: relative;
    }
    .gnav-sp{
      position: relative;
      &:after, &:before {
        content: "";
        position: absolute;
        transition: $all;
        top: 32px;
        right: 0;
        width: 16px;
        height: 2px;
        background: #222;
      }
      &:after {
        top: 25px;
        right: 7px;
        width: 2px;
        height: 16px;
      }
      &.active:hover {
        &:after {
          transform: rotate(90deg);
        }
      }
    }

    .h_gnav_list__txt{
      color: $color_1;
      padding: 20px;
      padding-left: 0;
      font-family: $font_1;
      font-weight: 700;
      justify-content: flex-start;
      height: auto;
      &:after{
        width: 100%;
        height: 1px;
        background: $color_7;
        top: 100%;
      }
    }
  }
  .drawer-opened {
    .h_wrap {
      height: 100%;
    }
    .h_drawer-wrap {
      overflow-y: scroll;
      height: 100%;
      padding-top: $height_3;
      padding-bottom: 400px;
      width: 100%;
    }
    .h_btn-menu {
      .icon-bar {
        &:nth-child(1) {
          -ms-transform: translate(0, 12px) rotate(45deg);
          transform: translate(0, 12px) rotate(45deg);
        }

        &:nth-child(2) {
          -ms-transform: translateX(200px);
          transform: translateX(200px);
        }

        &:nth-child(3) {
          -ms-transform: translate(0, -12px) rotate(-45deg);
          transform: translate(0, -12px) rotate(-45deg);
          width: 34px;
        }
      }
    }
    .h_drawer {
      opacity: 1;
      right: 0;
      -webkit-transition: $all;
      -moz-transition: $all;
      -ms-transition: $all;
      transition: $all;
      -webkit-transform: none;
      transform: none;
      z-index: 10;
    }
  }

}//@include h_change



@include sp {
  .h_drawer{
    width: 100%;
  }
  .h_btn-menu{
    width: 63px;
    height: 63px;
  }
}//@include sp

//h_scroll
.h_scroll{
  &.l_header{
    background: rgba($white,0.7);
    height: $height_2; 
  }
  .h_logo_1{
    display: none;
  }
  .h_logo_2{
    display: block;
    @include tab_content{
      width: 130px;
    }
  }
  .h_contact{
    height: $height_2;
  }
  .h_gnav_list__txt{
    color: $color_1;
  }
  @include h_change {
    &.l_header{
      height: $height_3; 
    }
    .h_btn-menu{
      width: $height_3;
      height: $height_3;
    }
    .h_btn-bg {
      .icon-bar {
        margin-bottom: 10px;
        margin-left: 14px;
        &:nth-child(1) {
          width: 34px;
        }
        &:nth-child(2) {
          width: 26px;
        }
        &:nth-child(3) {
          width: 18px;
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}



.drawer-opened{
  .h_scroll{
    @include h_change {
      .h_btn-menu{
        width: $height_3;
        height: $height_3;
        .icon-bar {
          &:nth-child(1) {
            -ms-transform: translate(0, 12px) rotate(45deg);
            transform: translate(0, 12px) rotate(45deg);
          }

          &:nth-child(2) {
            -ms-transform: translateX(200px);
            transform: translateX(200px);
          }

          &:nth-child(3) {
            -ms-transform: translate(0, -12px) rotate(-45deg);
            transform: translate(0, -12px) rotate(-45deg);
            width: 34px;
          }
        }
      }
    }
  }
}

/* =====================
  subMenu 
===================== */
.subMenu {
  font-family: $font_1;
  position: absolute;
  top: 100%;
  display: none;
  width: 100%;
  margin: 0;
  z-index: 10;
  left : 0;
  background: $white;
  @include h_change{
    position : static;
    overflow : hidden;
    flex-direction : column;
    width : 100%;
    padding : 0;
  }
}
.subMenu-inner {
  display :         flex;
  align-items : center;
  max-width : 1140px;
  margin : 0 auto;
  position: relative;
}
.subMenu-list {
  display : flex;
  width : 100%;
  max-width : 1140px;
  margin : 0 auto;
  text-align : center;
  justify-content : center;
  flex-wrap : wrap;
  @include h_change{
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    .subMenu-list_item{
      width: 100%;
    }
  }
}

@media screen and (max-width: 980px) {
    .subMenu-list  {
      .c_f_lsit_item_2{
        display: block;
        width: 100%;
      }
    }
    .h_gnav_list {
      .gnav-toggle{
        &:first-child{
          .subMenu-list{
            margin-left: 0;
          }
        }
      }
    }
}

/* =================================
 l_header_child
================================= */

.l_header_child{
  background: rgba(255, 255, 255, 0.7);
  .h_gnav_list{
    .h_gnav_list__txt{
      color: $color_1;
    }
  }
}
/* =================================
 l_breadcrumbs
================================= */
.l_breadcrumbs {
  overflow: hidden;
  background: $color_13;
  padding: 8px 0;
  .l_breadcrumbs_list {
    font-size: 1.2rem;
    display: table;
    padding-left: 40px;
    list-style: none;
    .l_breadcrumbs_item {
      display: table-cell;
      vertical-align: middle;
      white-space: nowrap;
      .link{
        position: relative;
        display: block;
        padding-right: 15px;
        margin-right: 15px;
        transition: $all;
        text-decoration: underline;
        &:after{
          content: "";
          position: absolute;
          top: 50%;
          right: 0;
          width: 4px;
          height: 4px;
          border-top: 1px solid $color_1;
          border-right: 1px solid $color_1;
          -webkit-transform:translate(0, -50%) rotate(45deg);
          transform:translate(0, -50%) rotate(45deg);
        }
      }
      &:last-child{
        padding-right: 40px;
      }
    }
  }
  @include sp{
    overflow-x: scroll;
    .l_breadcrumbs_list{
      font-size: 1.0rem;
      padding-left: 20px; 
    }
  }
}

.hover .l_breadcrumbs_list{
  .l_breadcrumbs_item{
    .link{
      &:hover{
        text-decoration: none;
      }
    }
  }
}

/* =================================
 l_main_visual
================================= */
//l_main_visual_top
.l_main_visual_top {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-bottom: 72px;
  &:after {
    content: "";
    width: 100%;
    height: 100%;
    background-size: cover;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;
  }
  @include sp{
    padding-bottom: 55px;
  }
}

//l_main_visual_child
.l_main_visual_child{
  position: relative;
  padding-top: 114px;
  background: $white;
  .ttl{
    position: relative;
    padding: 0;
    margin: 0 auto;
    width: 100%;
    max-width: 1100px;
  }
  @include h_change{
    padding-top: 63px;
  }
  @include sp{
    padding-top: 64px;
    .ttl{
      padding: 0 20px;
      top: calc(50% + 32px);
    }
  }
}

.l_main_visual_child_picWrap{
  position: relative;
  width: 100%;
  padding: 112px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
  background: $color_31;
  &.size_S{
      padding: 64px;
  }
  .img{
    position: absolute;
    top: 50%;
    left: 50%;
    width: auto;
    min-width: 100%;
    height: auto;
    min-height: 100%;
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    height: 300px;
    width: 1366px;
    object-fit: cover;
    -webkit-backface-visibility: hidden;
  }
  @include h_change{
      padding: 104px 20px;
  }
  @include sp{
    padding: 70px 0;
    &.size_S{
    padding: 70px 0;
    }
    .img{
      height: 200px;
      width: 377px;
      object-fit: cover;
      -webkit-backface-visibility: hidden;
    }
  }
}




//l_mv_child_triangle_1
.l_mv_child_triangle_1 , .l_mv_child_triangle_2, 
.l_mv_child_triangle_3{
  transition: $all_2;
  position: absolute;
  top: 0;
  &:before{
    content:"";
    border-style: solid;
    border-width: 300px 185px 0 0;
    border-color: $color_sub_2 transparent transparent transparent;
    display: block;
    transition: $all;
  }
  @include sp {
    &:before{
      border-width: 163px 100px 0 0;
    }
  }
}

.l_mv_child_triangle_1{
  left: -0;
  opacity: 0.1;
}
.l_mv_child_triangle_2{
  left: -48px;
  opacity: 0.4;
  @include sp{
    left: -32px;
  }
}
.l_mv_child_triangle_3{
  left: 0;
  opacity: 0.4;
  &:before{
    border-width: 152px 94px 0 0;
  }
  @include sp{
    &:before{
      border-width: 88px 54px 0 0;
    }
  }
}
//l_main_visual_child_date
.l_main_visual_child_date{
  font-size: 1.4rem;
  font-family: $font_1;
  color: $white;
  display: flex;
  margin-top: 20px;
  align-items: center;
  .c_cat_3{
    margin-bottom: 0;
  }
  .date{
    margin-right: 16px;
  }
  @include sp{
    flex-direction : column;
    align-items: flex-start;
    font-size: 1.2rem;
    .item{
      margin-top: 8px;
    }
  }
}
/* =================================
 l_content
================================= */
.l_content {
}

/* =================================
 l_main
================================= */
.l_main {
}

/* =================================
 l_side
================================= */
.l_side {
  width: $content_width_7;
}

/* =================================
 l_2col
================================= */
.l_2col{
  display: flex;
  .l_2col_main{
    flex:1;
    margin-right: 60px;
  }
  .l_2col_side{
    width: $content_width_7;
  }
  @include sp {
    flex-direction : column;
    .l_2col_main{
      margin-right: 0;
    }
    .l_2col_side{
      width: 100%;
    }
  }
}


.l_side_cont{
  margin-top: 50px;
}
.l_side_cont_SS{
  margin-top: 20px;
}
/* =================================
 l_footer
================================= */
.l_footer {
  width: 100%;
  margin: 0 auto;
  background: $white;
  position: relative;
  @include sp {
   padding-top: 10px;
   padding-bottom: 100px;
 }
}
/* =================================
 l_footer_cv
================================= */
.l_footer_cv {
  display: none;
  @include sp {
    z-index: 10;
    width: 100%;
    padding: 10px 16px;
    display: block;
    position: fixed;
    left: 0;
    bottom: 0;
    background: rgba($color_2,0.7);
    .txt{
      color: $white;
      font-family:$font_2;
      font-size: 1.6rem;
      margin-bottom: 6px;
      text-align: center;
    }
    .btnArea{
      display: flex;
      justify-content: space-between;
    }
    .c_btn_1{
      min-width: 1px;
      width: 100%;
      padding: 8px;
      .c_btn_1_txt{
        font-size: 1.6rem;
      }
    }
  }
}
/* =================================
 l_bnrArea
================================= */
.l_bnrArea {
  background: $color_4;
  padding: 40px 20px;
  position: relative;
  z-index: 4;
}
/* =================================
 l_cvArea
================================= */
.l_cvArea {
  background: $color_25;
  padding: 56px 20px;
  position: relative;
  z-index: 4;
  .tel_link{
    color: $white;
    font-size: 1.7rem;
    display: block;
  }
  @include sp {
    .u_txt_3{
      margin-top: 20px;
    }
    .c_btn_1{
      margin-top: 20px;
      &:first-child{
        margin-top: 0;
      }
    }
  }
}
/* =================================
 l_sec
================================= */
//l_secTB
.l_secTB{
  margin: 80px 0;
  @include sp {
      margin: 40px 0;
  }
}
//l_secTB_2
.l_secTB_2{
  margin: 80px 0;
  @include sp {
      margin: 50px 0;
  }
}
//l_secTB_3
.l_secTB_3{
  margin: 40px 0 80px;
  @include sp {
      margin: 40px 0 50px;
  }
}
//l_secTB_4
.l_secTB_4{
  margin: 80px 0 60px;
  @include sp {
      margin: 50px 0;
  }
}
//l_secTB_5
.l_secTB_5{
  margin: 80px 0;
  @include sp {
      margin: 80px 0 50px;
  }
}
//l_secTB_6
.l_secTB_6{
  margin: 50px 0 20px;
}
//l_secTB_S
.l_secTB_S{
  margin: 40px 0;
  @include sp {
      margin: 20px 0;
  }
}
//l_secTop
.l_secTop{
  margin-top: 80px;
  @include sp {
      margin-top: 40px;
  }
}
//l_secTop_2
.l_secTop_2{
  margin-top: 80px;
  @include sp {
      margin-top: 50px;
  }
}
//l_secTop_3
.l_secTop_3{
  margin-top: 55px;
  @include sp {
      margin-top: 35px;
  }
}
//l_secTop_4
.l_secTop_4{
  margin-top: 60px;
  @include sp {
      margin-top: 40px;
  }
}
//l_secTop_5
.l_secTop_5{
  margin-top: 120px;
  @include sp {
      margin-top: 50px;
  }
}
//l_secTop_6
.l_secTop_6{
  margin-top: 60px;
  @include sp {
      margin-top: 30px;
  }
}
//l_secTop_7
.l_secTop_7{
  margin-top: 60px;
  @include sp {
      margin-top: 50px;
  }
}
//l_secTop_2_sp
.l_secTop_2_sp{
  @include sp {
      margin-top: 50px;
  }
}
//l_secTop_S
.l_secTop_S{
  margin-top: 40px;
  @include sp {
      margin-top: 20px;
  }
}
//l_secTop_S_2
.l_secTop_S_2{
  margin-top: 30px;
}
//l_secTop_S_3
.l_secTop_S_3{
  margin-top: 40px;
  @include sp {
      margin-top: 30px;
  }
}
//l_secTop_S_4
.l_secTop_S_4{
  margin-top: 30px;
}
//l_secTop_S_5
.l_secTop_S_5{
  margin-top: 40px;
}
//l_secTop_S_6
.l_secTop_S_6{
  margin-top: 40px;
  @include sp {
      margin-top: 10px;
  }
}
//l_secTop_SS
.l_secTop_SS{
  margin-top: 20px;
}
//l_secTop_SS_2
.l_secTop_SS_2{
  margin-top: 20px;
  @include sp {
      margin-top: 10px;
  }
}

//l_secTop_SS_3
.l_secTop_SS_3{
  margin-top: 5px;
}
//l_secTop_SS_4
.l_secTop_SS_4{
  margin-top: 10px;
  @include sp {
      margin-top: 20px;
  }
}
//l_secTop_SS_5
.l_secTop_SS_5{
  margin-top: 20px;
  @include sp {
      margin-top: 60px;
  }
}
//l_secTop_SS_6
.l_secTop_SS_6{
  margin-top: 10px;
}
//l_secTop_SS_7
.l_secTop_SS_7{
  margin-top: 20px;
  @include sp {
      margin-top: 15px;
  }
}
//l_secBottom
.l_secBottom{
  margin-bottom: 80px;
  @include sp {
      margin-bottom: 40px;
  }
}
//l_secBottom_2
.l_secBottom_2{
  margin-bottom: 80px;
  @include sp {
      margin-bottom: 50px;
  }
}
//l_secBottom_S
.l_secBottom_S{
  margin-bottom: 40px;
  @include sp {
      margin-bottom: 20px;
  }
}
//l_secBottom_SS
.l_secBottom_SS{
  margin-bottom: 20px;
  @include sp {
      margin-bottom: 10px;
  }
}
//l_secBottom_SS_2
.l_secBottom_SS_2{
  margin-bottom: 20px;
}
//l_secLeft_10_u_sp
.l_secLeft_10_u_sp{
  @include sp {
      margin-left: 10px;
  }
}
//