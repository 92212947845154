/* ======================================================================
 utility
====================================================================== */

/* =================================
 layout
================================= */
// clearfix
.u_cf {
  @include cf;
}

// pc・sp 出し分け（media-queryはサイトの仕様に合わせて変更すること）
@include pc_tab {
  .u_pc {
    display: block;
    &_i {
      display: inline;
    }
    &_ib {
      display: inline-block;
    }
    &_f {
      display: flex;
    }
    &_if {
      display: inline-flex;
    }
  }
  .u_sp {
    display: none;
    &_i,
    &_ib,
    &_f,
    &_if {
      display: none;
    }
  }

}

@include sp {
  .u_pc {
    display: none;
    &_i,
    &_ib,
    &_f,
    &_if {
      display: none;
    }
  }
  .u_sp {
    display: block;
    &_i {
      display: inline;
    }
    &_ib {
      display: inline-block;
    }
    &_f {
      display: flex;
    }
    &_if {
      display: inline-flex;
    }
  }
}

.u_h_change_sp {
  display: none;
}
@include h_change {
  .u_h_change_sp {
    display: block;
    &_i {
      display: inline;
    }
    &_ib {
      display: inline-block;
    }
    &_f {
      display: flex;
    }
    &_if {
      display: inline-flex;
    }
  }

}



/* =================================
 content_size
================================= */
.u_content_size {
  position: relative;
  padding: 0;
  margin: 0 auto;
  width: 100%;
  max-width: $content_width;
  @include h_change{
    padding: 0 20px;
  }
}
.u_content_size_L {
  position: relative;
  padding: 0;
  margin: 0 auto;
  width: 100%;
  max-width: $content_width_6;
  @include h_change{
    padding: 0 20px;
  }
}
.u_content_size_s{
  position: relative;
  padding: 0;
  margin: 0 auto;
  width: 100%;
  max-width: $content_width_5;
  @include h_change{
    padding: 0 20px;
  }
}
.u_content_size_s_2{
  position: relative;
  padding: 0;
  margin: 0 auto;
  width: 100%;
  max-width: $content_width_8;
  @include h_change{
    padding: 0 20px;
  }
}
/* =================================
 text
================================= */
.u_txt_1 {
  font-size: 1em;
  line-height: 1;
  letter-spacing: .1em;
}

.u_txt_2 {
  font-size: 1em;
  line-height: 1;
  letter-spacing: .1em;
}

.u_txt_3 {
  font-size: 2.5em;
  color: $white;
  font-family: $font_2;
  @include pc_tab {
    font-size: 3.2em;
    line-height: 2;
  }
}

.u_txt_4 {
  font-size: 1.2rem;
  font-family: $font_1;
  line-height: 1.3;
  margin-top: 4px;
}

.u_txt_ttl_bottom{
  font-size: 1.6rem;
  margin-bottom: 40px;
  line-height: 1.75;
  position: relative;
  z-index: 3;
}
/* =================================
 text weight
================================= */
.u_lighter {
  font-weight: lighter;
}

.u_bold {
  font-weight: bold;
}

/* =================================
 text align
================================= */
.u_ta_c {
  text-align: center;
}

.u_ta_r {
  text-align: right;
}
.u_ta_cl {
  @include sp {
    text-align: center;
  }
}

.u_ta_lc {
  @include pc_tab {
    text-align: center;
  }
}

.u_ta_cr {
  @include sp {
    text-align: center;
  }
  @include pc_tab {
    text-align: right;
  }
}

.u_ta_rc {
  @include sp {
    text-align: right;
  }
  @include pc_tab {
    text-align: center;
  }
}

/* =================================
 paragraph margin
================================= */
.u_paragraph {
  & + & {
    margin: 15px 0 0;
  }
}

/* =================================
 hover animation
================================= */
.u_hover_1 {
  transition: opacity .3s;
  @include pc {
    &:hover {
      opacity: .7;
    }
  }
}

/* =================================
 hoge
================================= */
// .u_hoge {
//   hoge
// }

/* =================================
 tel:
================================= */
a[href*="tel:"]{
  pointer-events: none;
  @include sp{
    pointer-events: initial;
  }
}